.receipt-in {
    padding: 20px;
    margin-top: 85px;
    height: calc(100vh - 85px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: var(--theme-color);

    }

    @media screen and (max-width : 499px) {
        margin-top: 110px;
        height: calc(100vh - 110px);
    }
}

.invoice-container {
    width: 100%;
    background-color: #fff;
    padding: 40px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media screen and (max-width: 548px) {
        padding: 20px 10px;
        border-radius: 0;
    }

    .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 29.8px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        .company-logo {
            @media screen and (max-width: 768px) {
                width: 100%;
                text-align: center;
            }

            img {
                width: 100px;
            }
        }

        .company-details {

            @media screen and (max-width: 768px) {
                text-align: center;
                width: 100%;
            }

            h1 {
                font-size: 50px;
                margin-bottom: 10px;
                color: var(--secondary);
                font-weight: 600;
            }

            p {
                margin-bottom: 5px;
                font-size: 18px;
                color: var(--gray-color);

            }
        }
    }

    .invoice-details {
        border-top: 1px solid #ea7b44;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 30px 0;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            gap: 15px;
        }

        .bill-to {
            p {
                font-size: 16px;
                margin-bottom: 0;
                padding-bottom: 7px;
                font-weight: 500;
            }
        }

        .invoice-info {
            p {
                font-size: 16px;
                margin-bottom: 0;
                padding-bottom: 7px;
                font-weight: 500;
            }
        }
    }

    .invoice-table {
        width: 100%;

        @media screen and (max-width: 768px) {
            overflow-x: auto;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 30px;

            @media screen and (max-width: 768px) {
                margin-bottom: 0px;
            }

            td {
                border: 1px solid #ddd;
                padding: 10px;
                text-align: center;
                font-size: 14px;
                color: #333;
                background-color: #fff9f3;
            }

            th {
                border: 1px solid #ddd;
                padding: 10px;
                text-align: center;
                font-size: 14px;
                color: #333;
                background-color: #fff9f3;
                font-weight: 600;
            }
        }
    }

    .invoice-summary {
        max-width: 400px;
        margin-left: auto;

        @media screen and (max-width: 768px) {
            max-width: 100%;
            margin-top: 20px;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 17px;
            font-weight: 500;
            color: #000;
            padding-bottom: 10px;

            &:first-child {
                font-size: 21px;
                font-weight: 500;
                color: #000;
                padding-bottom: 10px;
            }

            &:last-child {
                font-size: 21px;
                font-weight: 500;
                color: #000;
                border-top: 1px solid #ea7b44;
                padding-top: 10px;
            }
        }
    }

    .last-info {
        text-align: center;
        margin-top: 10px;
        border-top: 1px solid #ea7b44;
        padding-top: 10px;

        h4 {
            font-size: 18px;
            font-weight: 500;
            color: #000;
            margin-bottom: 0;
            padding-bottom: 10px;
        }

        p {
            font-size: 15px;
            padding-bottom: 5px;
            color: #000;
        }

        a {
            color: #ea7b44;
        }
    }
}