:root {
    --button-clip-width: 12px;
    --button-clip-top-left: var(--button-clip-width);
    --button-clip-top-right: calc(100% - var(--button-clip-width));
    --button-clip-bottom-left: var(--button-clip-width);
    --button-clip-bottom-right: calc(100% - var(--button-clip-width));
}

.custom-action-button {
    --button-clip: polygon(var(--button-clip-top-left) 0%, var(--button-clip-top-right) 0%, 100% 50%, var(--button-clip-bottom-right) 100%, var(--button-clip-bottom-left) 100%, 0% 50%);
    clip-path: var(--button-clip);
    background-color: var(--theme-color);
    padding: 2px;

    &.white-outline {
        background-color: var(--white-color);
    }

    &.orange-outline {
        background-color: var(--theme-color);
    }

    &.success-outline {
        background-color: var(--success);
    }

    &.gary-light-outline {
        background-color: var(--input-bg);

        &.wt {
            width: 120px;
            margin: 0 auto;

            @media screen and (max-width : 768px) {
                width: 100%;
                background-color: var(--white-color);
            }
        }
    }

    &.danger-outline {
        background-color: var(--danger);
    }

    &.black-outline {
        background-color: var(--secondary);
    }

    button {
        background-color: transparent;
        box-shadow: none;
        padding: 6px 20px;
        border: 0;
        font-size: 16px;
        font-weight: 800;
        position: relative;
        display: inline;
        align-items: center;
        gap: 3px;
        width: 100%;
        justify-content: center;
        white-space: nowrap;
        --button-clip: polygon(var(--button-clip-top-left) 0%, var(--button-clip-top-right) 0%, calc(100% - 1px) 50%, var(--button-clip-bottom-right) 100%, var(--button-clip-bottom-left) 100%, 1px 50%);
        clip-path: var(--button-clip);
        background-color: var(--white-color);
        z-index: 0;
        color: var(--theme-color);
        text-transform: uppercase;

        .link-a {
            width: 100%;
            display: block;
            color: var(--secondary);
            padding: 4px 0 !important;

            &:hover {
                color: var(--white-color);
                background-color: var(--theme-color);
            }
        }

        @media screen and (max-width : 768px) {
            padding: 8px 20px;
        }

        @media screen and (max-width : 390px) {
            font-size: 12px;
        }

        &.pd-add {
            @media screen and (max-width : 768px) {
                padding: 9px 43px;
            }
        }

        &.w-pd-add {
            padding: 8px 43px;
        }

        &.disabled-button {
            pointer-events: none;
            color: #ea7b445b !important;
            opacity: 0.6;
            cursor: not-allowed !important;
        }


        img {
            width: 17px !important;
            height: 24px;
            margin-right: 10px;
        }

        &.bg-orange {
            // color: var(--white-color);
            // background-color: var(--theme-color);
            background-color: var(--white-color);
            color: var(--theme-color);

            &:hover {
                // background-color: var(--white-color);
                // color: var(--theme-color);
                color: var(--white-color);
                background-color: var(--theme-color);
            }
        }

        &.bg-dark-gray {
            color: var(--white-color);
            background-color: var(--gray-dark);

            &:hover {
                background-color: var(--theme-color);
                color: var(--secondary);
            }
        }

        &.bg-dark-black {
            color: var(--white-color);
            background-color: var(--secondary);

            &:hover {
                background-color: var(--theme-color);
            }
        }

        &.success-bg-color {
            // background-color: var(--success);
            color: var(--secondary);
            background-color: var(--white-color);

            &:hover {
                // background-color: var(--white-color);
                background-color: var(--success);
            }
        }

        &.gary-bg {
            // background-color: var(--input-bg);
            background-color: var(--white-color);
            color: var(--secondary);

            &:hover {
                // background-color: var(--white-color);
                background-color: var(--input-bg);
                color: var(--secondary);
            }

        }

        &.bg-danger {
            color: var(--white-color);
            background-color: var(--danger) !important;

            &:hover {
                background-color: var(--white-color) !important;
                color: var(--secondary);
            }
        }

        &.bg-dark-gray-success {
            color: var(--white-color);
            background-color: var(--gray-dark);

            &:hover {
                background-color: var(--success);
                color: var(--secondary);
            }
        }

        &.white-bg {
            background-color: var(--white-color);
            color: var(--theme-color);

            &:hover {
                background-color: var(--theme-color);
                color: var(--white-color);
                // filter: invert(1);
            }
        }
    }

}

button:hover img {
    filter: brightness(0) invert(1);
}

.tooltip-logout:hover img {
    filter: brightness(1) invert(0);
}

.close-button-icon:hover img {
    filter: brightness(1) invert(0);
}

.stat-icon {
    filter: brightness(0) invert(2);
}

.tooltip-logout img {
    width: 25px;
}

.triangle-card {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -5px;
        right: -9px;
        width: 0;
        height: 0;
        border-bottom: 39px solid transparent;
        border-right: 34px solid var(--white-color);
        transform: rotate(371deg);
        z-index: 1;

    }

    &.triangle-m-t {
        @media screen and (max-width : 768px) {
            &::before {
                right: -9px;
                transform: rotate(375deg);
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        right: 7px;
        top: -4px;
        width: 3px;
        height: 38px;
        background-color: var(--theme-color);
        transform: rotate(-29deg);
        // z-index: 2;
    }

    &.ling-set {
        &::after {
            top: -2px;
            height: 35px;

        }
    }

    &.line-card {
        &::after {
            right: 7px;
            top: -4px;
            width: 3px;
            height: 38px;
            transform: rotate(-30deg);
        }
    }
}