:root {
    --link-color: #ecf0f1;
    --link-active-color: #f1c40f;
    --button-clip-width: 13px;
    --button-clip-top-left: var(--button-clip-width);
    --button-clip-top-right: calc(100% - var(--button-clip-width));
    --button-clip-bottom-left: var(--button-clip-width);
    --button-clip-bottom-right: calc(100% - var(--button-clip-width));
}



// side bar css start
header {
    #checked {
        position: fixed;
        top: 0;
        top: 30px;
        z-index: 4;
        display: none;

    }

    .click {
        position: fixed;
        z-index: 2;
        top: 26px;
        color: var(--theme-color);
        right: 13px;
        font-size: 28px;
        display: none;

        // @media screen and (max-width:992px) {
        //     display: block;

        // }

        @media screen and (max-width:500px) {
            top: 58px;
            right: 20px;
        }
    }

    position: relative;
    z-index: 3;

    .navbar-bg {
        background-image: url('../../../public/assets/images/background.png');
        max-width: 230px;
        width: 100%;
        height: 100vh;
        background-size: 500px;
        position: fixed;
        overflow: auto;
        padding-bottom: 100px;
        transition: all 0.5s ease-in-out;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: var(--theme-color);

        }

        @media screen and (max-width : 992px) {
            z-index: 2;
            transform: translateX(-100%);
        }

        .logo-dashboard {
            display: block;
            position: relative;
            z-index: 5;
        }

        .nav-logo {
            padding: 40px 30px 30px;
            margin-bottom: 0px;
            width: 220px;
        }

        .listing-bar {
            list-style: none;
            padding: 0 0px;
            display: block;
            margin-bottom: 0;

            li {
                &.active-border {
                    border-bottom: 2px solid var(--theme-color);

                }
            }

        }

        .listing-bar li a {
            font-size: 18px;
            color: var(--gray-color);
            text-decoration: none;
            padding: 0px 20px 0px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: 500;

            &:hover {
                color: var(--theme-color);
            }

            &.active-color {
                color: var(--theme-color);
                font-weight: 800;
            }
        }

        .listing-bar ul {
            padding: 0px 0 5px;
            background-color: var(--secondary);
            border-top: 2px solid var(--theme-color);
            transition: .8s;
            list-style: none;
        }

        .listing-bar ul li {
            padding-left: 0;

        }

        .listing-bar ul li a {
            font-size: 13px;
            padding-top: 7px;
            padding-bottom: 7px;
            margin-bottom: 0;
            display: flex;
            align-items: center;

            span {
                padding-left: 15px;
            }

            .img-icon {
                display: block;
                padding-left: 0;
                aspect-ratio: 1/1;
                width: 30px;

                img {
                    height: 100%;
                    width: 100%;


                }
            }
        }

        .create-page {
            width: 150px;

        }

        .input-search {
            background-color: var(--gray-dark);
            position: relative;
            padding: 10px 20px 15px 10px !important;
            width: 100%;

            input {
                border: 0px;
                border-bottom: 1px solid var(--white-color);
                background-color: transparent;
                border-radius: 0;
                color: var(--white-color);
                font-weight: 500;
                padding-right: 20px;

                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
            }

            ::placeholder {
                color: var(--gray-color);
                font-size: 13px;
                font-weight: 500;
            }

            img {
                position: absolute;
                right: 20px;
                font-size: 15px;
                top: 21px;
                color: var(--theme-color);
            }

            &:hover ::placeholder {
                color: var(--white-color);
            }

            &:hover .fa {
                color: var(--white-color);
            }
        }
    }

    .sub-nav {
        @media screen and (max-width: 992px) {
            transform: translateX(-100%);
        }
    }
}



.click {
    // position: relative;
    color: var(--theme-color);
    font-size: 23px;
    display: none;

    @media screen and (max-width:992px) {
        display: block;

    }
}

#checked:checked~.click i::before {
    content: "\f00d" !important;
    color: var(--white-color);
    font-size: 30px;

}

.overlay {
    @media screen and (max-width: 992px) {
        position: fixed;
        left: 0;
        top: 0px;
        width: 100vw;
        height: 100%;
        background-color: #0000009a;
        z-index: 1;
    }
}

#checked:checked~.navbar-bg {
    transform: translateX(0%);
    transition: 00.5s all ease-in-out;
}

.nav-link[data-toggle].collapsed:after {
    // content: "▾";
    content: '';
    // flex: 1;
    // text-align: right;
    background-image: url('../../../public/assets/images/down-h.png');
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-left: auto;

}

.nav-link[data-toggle]:not(.collapsed):after {
    // content: "▴";
    // flex: 1;
    // text-align: right;
    content: '';
    background-image: url('../../../public/assets/images/up-h.png');
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-left: auto;
}

// side bar css end

//  top bar header css start

.nav-line {
    border-top: 10px solid var(--theme-color);
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    top: 0;

    @media screen and (min-width : 992px) {
        z-index: 4;
    }

    .nav-box {
        position: absolute;
        right: 350px;
        top: 0px;
        width: 400px;
        height: 40px;
        padding: 0 17px;

        @media screen and (max-width : 1199px) {
            display: none;
        }

        .drop-list {
            background-color: var(--secondary);
            // padding: 11px 20px;
            font-weight: 800;
            position: relative;
            border-bottom: 2px solid var(--theme-color);

            .range-button {
                text-decoration: none;
                display: block;
                color: var(--white-color);
                font-size: 14px;
                box-shadow: none;
                outline: 0;
                background-color: var(--secondary);
                padding: 11px 20px;

                span {
                    color: rgb(121, 118, 118);
                    font-style: italic;
                }


                &::before {
                    content: '';
                    position: absolute;
                    left: 14px;
                    bottom: -2px;
                    width: 93%;
                    height: 2px;
                    background-color: var(--theme-color);
                    z-index: 3;
                }

            }

            .action-drop {
                background-color: var(--secondary);
                transform: translate(15px, 44px) !important;
                padding: 0 0px;
                max-width: 339px;
                max-height: 300px;
                overflow-y: scroll;
                margin: 0 auto;
                width: 100%;
                border-radius: 0;

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar {
                    width: 0px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0px;
                    background-color: var(--theme-color);

                }

                li {

                    .dropdown-item {
                        color: var(--white-color);
                        font-weight: 500;
                        padding: 8px 15px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &:hover {
                            background-color: var(--theme-color);

                        }

                        p {
                            padding-right: 30px;
                            max-width: 300px;
                            overflow: hidden;
                        }
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: -13px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 25px solid var(--theme-color);
                border-right: 13px solid var(--secondary);
                border-bottom: 20px solid transparent;
                z-index: 1;
                border-left: 16px solid transparent;
            }

            &::after {
                content: "";
                position: absolute;
                right: -13px;
                top: 0;
                width: 0;
                height: 0;
                border-top: 23px solid var(--theme-color);
                border-left: 13px solid var(--secondary);
                border-bottom: 20px solid transparent;
                z-index: 1;
                border-right: 13px solid transparent;

            }

            .triangle {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 6px;
                    top: 16px;
                    width: 0px;
                    height: 30px;
                    background-color: var(--theme-color);
                    z-index: 2;
                    border-right: 3px solid transparent;
                    transform: rotate(-30deg);

                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 4px;
                    top: 14px;
                    width: 2px;
                    height: 32px;
                    z-index: 2;
                    background-color: var(--theme-color);
                    border-left: 3px solid transparent;
                    transform: rotate(30deg);
                }
            }

            .bg-color-remove {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: -12px;
                    bottom: -45px;
                    width: 0;
                    height: 0;
                    border-bottom: 43px solid var(--white-color);
                    border-right: 29px solid transparent;
                    transform: rotate(3deg);
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: -15px;
                    bottom: -49px;
                    width: 0;
                    height: 0;
                    border-bottom: 45px solid var(--white-color);
                    border-right: 35px solid transparent;
                    transform: rotate(-114deg);
                }
            }
        }
    }


    .shot-pro-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 40px 11px 0px;
        text-align: center;
        background-color: var(--white-color);
        width: calc(100% - 230px);
        margin-left: auto;
        border-bottom: 2px solid var(--theme-color);
        gap: 20px;

        @media screen and (max-width:992px) {
            width: 100%;
            padding: 11px 50px 11px 20px;
        }

        @media screen and (max-width:668px) {
            justify-content: center;
            flex-wrap: wrap;
            gap: 12px;
            padding: 11px 20px 11px 20px;
        }


        .user-changes {
            display: flex;
            align-items: center;
            gap: 50px;
            padding-left: 30px;
            width: 420px;
            text-align: left;

            @media screen and (max-width : 767px) {
                width: 100%;
                word-wrap: break-word;
                text-align: left;
                padding-left: 10px;

            }

            @media screen and (max-width : 699px) {
                text-align: center;
                justify-content: center;
            }

            img {
                width: 150px;
            }

            p {
                color: var(--secondary);
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        .user-detail {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 264px;
            justify-content: flex-end;

            @media screen and (max-width : 992px) {

                width: 100%;
            }

            @media screen and (max-width : 668px) {
                justify-content: space-between;
            }

            >div {
                text-align: left;

                .box-bg {
                    flex: 0 0 45px;
                }
            }

            h3 {
                font-size: 16px;
                color: var(--secondary);
                margin-bottom: 0;
                font-weight: 800;
            }

            p {
                color: var(--theme-color);
                font-style: italic;
                font-size: 13px;
                margin-bottom: 0;
            }

            .user-id {
                display: flex;
                align-items: center;
                gap: 20px;

                .bill-icon {
                    display: flex;
                    position: relative;

                    img {
                        width: 20px;
                    }

                    .number-add {
                        position: absolute;

                        top: -3px;
                        right: -5px;
                        height: 14px;
                        width: 14px;
                        background-color: var(--danger);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        color: var(--white-color);
                        font-size: 10px;
                        font-weight: 800;
                    }
                }

                .dote-list {
                    .dotes-link {
                        background-color: transparent;
                        border: 0px solid;
                        color: var(--theme-color);

                    }

                    .dote-menu {
                        padding: 0;
                        background-color: var(--secondary);
                        box-shadow: none;
                        border: 0px solid;
                        transform: translateY(30px) !important;

                        li {

                            a {
                                color: var(--white-color);
                                padding: 5px 10px;
                                font-size: 16px;
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;

                                &:hover {
                                    color: var(--white-color);
                                    background-color: var(--theme-color);
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}

//  top bar header css end
.dropdown-toggle {
    &::after {
        margin-left: 40px;
        border-top: 0;
        border-left: 0;
        background-image: url('../../../public/assets/icon/dropdown_arrow_white.svg');
        width: 20px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: 15px;
        transform: translate(-4px, 7px);
        position: absolute;
        right: 14px;
    }
}

.header-drop-down {
    padding: 0px 10px;
    margin-top: 0px;
    margin-bottom: 30px;

    .pin {
        background-color: var(--theme-color);
        padding: 2px;
        clip-path: var(--button-clip);
        --button-clip: polygon(var(--button-clip-top-left) 0%, var(--button-clip-top-right) 0%, 100% 50%, var(--button-clip-bottom-right) 100%, var(--button-clip-bottom-left) 100%, 0% 50%);
    }

    button {
        padding: 12px 10px;
        width: 100%;
        display: block;

        box-shadow: none;
        background-color: var(--secondary);
        color: var(--white-color);
        border: 0px;
        font-size: 14px;
        font-weight: 800;
        --button-clip: polygon(var(--button-clip-top-left) 0%, var(--button-clip-top-right) 0%, 100% 50%, var(--button-clip-bottom-right) 100%, var(--button-clip-bottom-left) 100%, 0% 50%);
        clip-path: var(--button-clip);

        &:hover {
            color: var(--white-color);
        }


        span {
            color: var(--gray-color);
            font-weight: 300;
            font-style: italic;
        }
    }

    .another {
        background-color: var(--secondary);
        width: 100%;
        padding: 0;
        transform: translate(24px, 50px) !important;
        padding: 0 0px;
        max-width: 183px;
        max-height: 200px;
        margin: 0 auto;
        width: 100%;
        border-radius: 0;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 0px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: var(--theme-color);
        }

        li {
            a {
                color: var(--white-color);
                font-weight: 500;
                font-size: 15px;
                white-space: break-spaces;

                &.dropdown-item:hover {
                    background-color: var(--theme-color);
                }

            }
        }
    }
}

.range-name {
    position: relative;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
}

.city-name {
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 13px;
        right: 4px;
    }
}

.cross-icon {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 2;

}

.tooltip-logout {
    background-color: transparent;
    border: 0;

    &:hover {
        background-color: transparent;
    }
}

.point-evn {
    pointer-events: none;
    opacity: 0.4;
}