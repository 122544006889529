@import './Components/index.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    box-shadow: none;

}

body {
    font-family: 'Gotham';
    font-weight: 500;
    background-color: var(--white-color);

    @media screen and (max-width : 568px) {
        overflow-y: hidden;
        cursor: pointer;
    }
}

.t-delete {
    background-color: transparent;
    border: 0;

    img {
        width: 30px;

        &:hover {
            filter: brightness(1) invert(0);
        }
    }
}

// responsive table td class add

.mob-tab-view {
    @media screen and (min-width:769px) {
        display: none;
    }
}

.mob-tab-view-2 {
    @media screen and (max-width:769px) {
        display: none !important;
    }
}

.all-top-heading {
    display: none;
    padding-left: 1px;

    @media screen and (max-width : 768px) {
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: var(--theme-color);
        margin-bottom: 0;
        padding-top: 20px;
    }
}


th {
    font-size: 15px;
    font-weight: 500;
    color: var(--secondary);
    text-transform: uppercase;
    padding: 0 10px;
}

td {
    font-size: 15px;
    font-weight: 500;
    color: var(--secondary);
}

a {
    text-decoration: none;
    color: var(--secondary);
    cursor: pointer;

    @media screen and (max-width : 500px) {
        word-break: break-word;
    }

    &:hover {
        color: var(--secondary);
    }
}

.width-th {
    @media screen and (max-width : 568px) {
        width: 180px;
    }
}

.display {
    @media screen and (max-width : 768px) {
        display: block;
    }
}

.w-set-table {
    @media screen and (min-width : 769px) {
        width: 250px;
        margin: 0 auto;
    }
}

.w-set-table-time {
    @media screen and (min-width : 769px) {
        width: 100px;
        margin: 0 auto;
    }
}

.wrapper {
    display: flex;

    .wrapper-body {
        padding-left: 250px;
        width: 100%;

        @media screen and (max-width : 992px) {
            padding-left: 0;
        }
    }
}

.outer-border {
    margin: 73px 0px 0;
    border-radius: 0;
    padding-top: 10px;
    height: calc(100vh - 73px);
    overflow-x: hidden;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--theme-color);
    }

    @media screen and (max-width : 667px) {
        margin: 120px 0 0px;
        height: calc(100vh - 123px);
    }

    @media screen and (max-width: 668px) {
        &.outer-ttt {
            margin: 80px 0 0px;
            height: calc(100vh - 80px);
        }
    }

    @media screen and (max-width: 568px) {
        &.outer-ttt {
            margin: 60px 0 0px;
            height: calc(100vh - 60px);
        }
    }

    .user-card-range {
        display: flex;
        border: 2px solid var(--theme-color);
        width: 100%;
        position: relative;
        margin-top: 51px;

        @media screen and (max-width : 768px) {
            flex-direction: column;
        }

        @media screen and (max-width : 400px) {
            margin-bottom: 100px;
        }

        .on-range {
            background-color: var(--theme-color);
            position: relative;
            padding: 10px 10px 10px 10px;
            flex: 1;
            outline: 0;
            text-align: center;

            @media screen and (max-width : 768px) {
                width: 100%;
                padding: 20px 20px 50px;
                outline: 0;
                border: 0;
            }

            h3 {
                color: var(--white-color);
                margin-bottom: 0;

                @media screen and (max-width : 768px) {
                    text-align: center;
                    font-size: 25px;
                    padding-bottom: 20px;
                }
            }

            .user-total {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                text-align: center;

                p {
                    font-size: 1.3vw;
                    color: var(--white-color);
                    max-width: 140px;
                    width: 100%;
                    margin: 0 auto;

                    @media screen and (min-width : 1599px) {
                        max-width: 100%;
                    }

                    @media screen and (max-width : 768px) {
                        font-size: 17px;
                        margin-bottom: 0;
                        max-width: 100%;
                    }



                }

                h3 {
                    font-size: 10vw;

                    @media screen and (max-width : 768px) {
                        font-size: 60px !important;
                    }
                }
            }


            .step-progress {
                position: absolute;
                right: -15px;
                top: 50px;


                &::after {
                    content: '';
                    position: absolute;
                    left: 15px;
                    top: 22px;
                    width: 3px;
                    height: 68%;
                    background-color: var(--white-color);

                }


                .progress-line {
                    display: block;
                    margin-bottom: 175px;
                    position: relative;
                    z-index: 1;
                }
            }
        }

        .training-2 {
            background-color: var(--secondary);
            flex: 0 0 0.7;
            padding: 40px 45px;
            position: relative;

            @media screen and (max-width : 768px) {
                width: 100%;
                padding: 40px 10px 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .class-users {
                text-align: center;
                position: relative;
                width: 100%;

                &::before,
                &:last-child::before {
                    content: '';
                    position: absolute;
                    left: -46px;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background-color: var(--white-color);

                    @media screen and (max-width : 768px) {
                        left: 50%;
                        top: -40px;
                        width: 100%;
                        height: 3px;
                    }
                }

                &:last-child::before {
                    height: 0%;
                }

                .progress-border {
                    position: absolute;
                    left: -67px;
                    top: -2px;
                    z-index: 1;


                    @media screen and (max-width : 768px) {
                        left: 50%;
                        top: -65px;
                        transform: translateX(-50%)
                    }


                }

                p {
                    text-transform: capitalize;
                    font-size: 1.3vw;
                    color: var(--white-color);
                    padding-bottom: 10px;
                    margin-bottom: 0;

                    @media screen and (max-width :768px) {
                        font-size: 20px;
                    }
                }

                h3 {

                    font-size: 4.4vw;
                    margin-bottom: 0;
                    font-weight: 800;
                    color: var(--white-color);
                    padding-bottom: 10px;

                    @media screen and (max-width : 768px) {
                        font-size: 40px;
                    }
                }
            }
        }
    }

    .progress-list-user {
        background-color: var(--theme-color);
        padding: 20px 20px;
        position: relative;
        margin: 30px 0;

        @media screen and (max-width : 568px) {

            margin-bottom: 100px;
        }

        h3 {
            font-weight: 800;
            color: var(--white-color);
            font-size: 32px;
            text-align: center;
            margin-bottom: 0;

            @media screen and (max-width : 412px) {
                padding-bottom: 15px;

            }

        }

    }

    .examination {
        display: flex;

        @media screen and (max-width : 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .user-table-list {
        margin-right: 20px;
        margin-top: 50px;

        @media screen and (max-width : 768px) {
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 20px;
        }

        h4 {
            display: block;
        }
    }

    .nav-link {
        color: var(--secondary);
    }

    .tabs-button-user {
        width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap !important;

        @media screen and (max-width : 768px) {
            margin-bottom: 15px;
        }

        .nav {
            flex-wrap: nowrap !important;
            padding-top: 20px;
        }

        .nav-link {
            border-radius: 0;
            padding: 15px 15px;
            text-align: left;
            border-right: 2px solid var(--theme-color);
            cursor: pointer;
            max-width: 160px;

            &:last-child {
                border-right: 0px solid;
            }

            @media screen and (max-width : 568px) {
                width: 100%;
            }

            h4 {
                // font-size: 16px;
                // @extend .tab-heading;
                font-size: 16px;
                // font-style: italic;
                margin-bottom: 0;
                font-weight: 800;
            }

            p {

                font-size: 14px;
                @extend .tab-sub-heading;
            }

            &.active {
                background-color: var(--theme-color);
            }
        }
    }

    .back-icon {
        color: var(--theme-color);

        .fa {
            font-size: 40px;
        }
    }

    .employees-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .all-date-selected {

        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        transform: translateY(10px);
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width : 768px) {
            justify-content: flex-end;
            width: 100%;
        }

        @media screen and (max-width : 568px) {
            justify-content: flex-start;
            width: 100%;
        }

        ::placeholder {
            font-weight: 500;
            font-size: 14px;
        }

        input {
            font-size: 16px;
            font-weight: 500;
            color: var(--secondary);
            width: 200px;

            @media screen and (max-width : 768px) {
                width: 100%;
            }
        }



        .select-input {
            .form-select {
                font-size: 16px;
                font-weight: 500;
                width: 200px;

                option {
                    font-weight: 500;
                    background-color: var(--secondary);
                    color: var(--white-color);
                    // padding: 8px 20px;
                }

                @media screen and (max-width : 768px) {
                    width: 100%;
                }
            }

            @media screen and (max-width : 768px) {
                width: 100%;
            }

        }

        .search-icon {
            width: 20px;
            height: 20px;
            margin-top: 10px;
        }

        @media screen and (max-width : 768px) {
            &.mobile-b-set {
                flex-wrap: nowrap !important;
            }
        }
    }
}

.outer-overflow-show {
    overflow-y: scroll !important;
}

.all-status {
    th {
        text-align: left;
    }
}

.all-user {
    padding: 15px 0px 0px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    overflow-x: auto;

    @media screen and (max-width : 412px) {
        flex-wrap: wrap;
    }

    @media screen and (max-width : 768px) {
        width: 100%;
        overflow-x: scroll;

    }

    &.all_member {
        @media screen and (max-width : 1299px) {
            width: 100%;
            flex-wrap: nowrap;
            overflow-x: scroll;
            margin-left: 20px;
        }
    }
}

.billy-name {
    display: flex;
    font-size: 20px;
    font-weight: 800;
    color: var(--secondary);
    margin-bottom: 0;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    justify-content: center;
}

.remove {
    border: 0px solid !important;
}

.processing-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    flex: 1 0 150px;
    z-index: 1;
    padding: 0 20px;

    @media screen and (max-width : 412px) {
        flex: 1 0 140px;
    }

    &::before,
    &:first-child:before {
        content: '';
        position: absolute;
        left: 0;
        top: 22px;
        width: 100%;
        height: 3px;
        background-color: var(--white-color);

        @media screen and (max-width :412px) {
            background-color: transparent;
        }
    }

    &:first-child:before {
        right: 0;
        left: auto;
        width: 50%;
    }

    &:last-child::before {
        left: 0;
        width: 50%;
    }

    &__text {
        width: 100%;
        // height: 180px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        margin: 10px 0;

        @media screen and (max-width : 412px) {
            margin-bottom: 30px;
        }

        p {
            font-size: 20px;
            font-weight: 500;
            color: var(--white-color);
            // padding-top: 20px;
            margin-bottom: 0;
            min-height: 60px;

            @media screen and (max-width : 768px) {
                font-size: 15px;
            }

            @media screen and (max-width : 412px) {
                min-height: 45px;
            }
        }

        h4 {
            font-size: 70px;
            color: var(--white-color);
            font-weight: 800;
            margin-bottom: 0;

            @media screen and (max-width : 412px) {
                font-size: 50px;
            }
        }
    }

}

.nav-event-pro {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        height: 7px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: var(--theme-color);

    }

}

.tab-nav-list {
    gap: 0px;
    margin-top: 30px;

    @media screen and (max-width : 992px) {
        justify-content: flex-start;
        padding-left: 0px;
    }
}

.tab-nav-list .nav-item div>button {
    padding: 8px 25px !important;
}

.last-btn {
    justify-content: flex-end !important;
}

.first-user-2 {
    display: flex;
    align-items: flex-start;
    // justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding: 0 0px;
    // width: calc(100%/8 - 3px);
    flex: 0 0 200px;
    padding-right: 10px;
    cursor: pointer;

    @media screen and (max-width : 768px) {
        flex: 1 0 140px;
    }


    &::before,
    &:first-child:before {
        content: '';
        position: absolute;
        left: 0;
        top: 17px;
        width: 100%;
        height: 3px;
        border: 2px dashed var(--input-bg);
    }

    &.nav-active-dash {
        position: relative;

        &::before {
            border: 2px dashed var(--theme-color);
        }
    }

    &:first-child:before {
        right: 0;
        left: auto;
        width: 100%;
    }

    &:last-child::before {
        left: 0;
        width: 0%;
    }

    h4 {
        background-color: var(--white-color);
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--secondary);

        &.active-hex {
            background-color: var(--theme-color);
            color: var(--white-color);
        }
    }


    p {
        font-size: 20px;
        font-weight: 500;
        color: var(--secondary);
        // padding-top: 20px;
        text-align: left;
        margin-bottom: 0;

        @media screen and (max-width : 768px) {
            font-size: 15px;
        }

        &.pro-active-color {
            color: var(--theme-color);
            margin-bottom: 0;
        }
    }
}

.list-start {
    background-color: var(--theme-color);
    padding: 3px;
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: relative;
    z-index: 1;


    h4 {
        background-color: var(--white-color);
        margin-bottom: 0;
        width: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        font-size: 20px;
    }
}

.listing-booking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--theme-color);
    padding: 15px 10px 15px 0;

    @media screen and (max-width : 769px) {
        flex-direction: column;
        row-gap: 10px;
        margin-bottom: 15px;
    }
}

.lane-booking {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-bottom: 0;

    &.lane-gap {
        @media screen and (max-width : 768px) {
            gap: 0;

        }
    }

    li {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 18px;

        @media screen and (max-width : 768px) {
            margin: 0 0px;
            font-size: 14px;
            gap: 5px;

        }

        img {
            width: 23px;
        }
    }

}

.order-title {
    font-size: 18px;
    font-weight: 800;
    color: var(--secondary);
}



.instructor {
    background-color: var(--theme-color);
    width: 100%;
    padding: 10px 30px;

    h3 {
        font-size: 20px;
        color: var(--white-color);
        margin: 0;
    }

    h5 {
        font-size: 15px;
        color: var(--white-color);
        font-style: italic;
        font-weight: 400;
        margin: 0;
    }
}

.button-width {
    @media screen and (max-width : 568px) {
        width: 100%;
        display: block !important;
    }
}

.employees {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 0px 0 20px;
    margin-bottom: 10px;

    @media screen and (max-width : 1025px) {
        column-gap: 20px;
    }

    &.employ-set {
        @media screen and (max-width : 568px) {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 10px;

            .card-top-2 {
                transform: translateY(0);
            }

            h3 {
                transform: translateY(0);
                padding-left: 10px;
            }

            .set-hh {
                transform: translateY(16px);
            }
        }
    }
}

.jeff {
    color: var(--theme-color);
    font-style: italic;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
}

.card-gun-id {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    text-align: center;

    @media screen and (max-width : 568px) {
        padding-bottom: 100px !important;
    }

    .user-card {
        border: 2px solid var(--theme-color);
        border-radius: 0;
        padding-top: 10px;
        width: 100%;

        // @media screen and (max-width:568px) {
        //     width: 100%;
        // }

        margin: 0 auto;

        img {
            width: 120px;
            height: 120px;
            margin: 0 auto;
        }
    }

    .card-body-2 {
        h4 {
            font-size: 21px;
            font-weight: 800;
            color: var(--secondary);
            margin: 0;
            padding: 10px 5px 0;
        }

        h5 {
            color: var(--theme-color);
            font-style: italic;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
            padding: 10px 5px 0;
        }

        p {
            font-size: 11px;
            padding: 0 10px;
            font-weight: 500;
            font-style: italic;
            margin-bottom: 0;
            padding: 10px 5px;
        }

        h6 {
            font-size: 12px;
            background-color: var(--theme-color);
            -o-animation-padding: 10px 5px;
            -webkit-padding: 10px 5px;
            padding: 10px 5px;
            font-weight: 500;
            font-style: italic;
            color: var(--white-color);
            margin-bottom: 0;
        }

        .gun {
            width: 100%;
            padding: 0;
            height: 45px;
            object-fit: cover;
        }
    }
}

.c-g-id {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.crop {
    border-spacing: 0 4px;
}

.hr-color-changes {
    margin: 20px 0px 0px;
    border: 1px solid var(--theme-color);

}

.check-right {
    position: relative;

    &::after {
        content: '\2713';
        position: absolute;
        bottom: -1px;
        left: 44%;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: var(--success);
        transform: rotate(29deg);
        padding-left: 3px;

    }
}

.right-icon-angel {
    text-align: right;
    width: 100%;
    padding-right: 30px;

    .fa {
        font-size: 40px;
        color: var(--white-color);
    }

    &.right {
        padding-right: 10px;
    }
}

.body-id-user {
    text-align: center;

    h4 {
        font-size: 16px;
        color: var(--theme-color);
    }

    .phat {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;

        .first-phat {
            border-right: 1px solid var(--theme-color);
            padding-right: 10px;

            p {
                margin: 0;
                font-size: 16px;
                color: var(--secondary);
            }
        }

        .seconde-phat {
            padding-left: 10px;

            p {
                margin: 0;
                font-size: 16px;
                color: var(--secondary);
            }
        }
    }
}

.user-id-card {

    border: 0px solid;

    border-radius: 0;

    .card-id {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 18px;

        @media screen and (max-width : 992px) {
            justify-content: center;
            flex-direction: column;
            // margin-bottom: 40px;
        }

    }
}

.all-profile {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding-right: 16px;
    flex-wrap: wrap;

    @media screen and (max-width : 992px) {
        padding-left: 0;
        justify-content: center;
    }
}

.account-range {
    // display: flex;
    // flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    // flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: 30px;

    @media screen and (max-width : 1024px) {
        margin-bottom: 0;
        margin-top: 40px;
    }

    h5 {
        font-size: 15px;
        color: var(--secondary);
        height: 30px;
        font-weight: 500;
        margin-bottom: 0;

        @media screen and (max-width : 768px) {
            word-break: break-all;
        }
    }

    h4 {
        margin: 0;
        font-size: 22px;
        color: var(--theme-color);
        padding: 5px 0 10px;
        font-weight: 800;
    }

}

.number-round {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 21px;
        top: 13px;
        width: 10px;
        height: 10px;
        background-color: var(--danger);
        border-radius: 50px;
    }
}

.classes-shot {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-row-gap: 30px;
    padding-bottom: 20px;

    @media screen and (max-width : 768px) {
        grid-row-gap: 30px;
    }

    .mm-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    h4 {
        font-size: 35px;
        color: var(--theme-color);
        font-weight: 500;
        margin-bottom: 0;

        @media screen and (max-width : 768px) {
            font-size: 20px;
        }
    }

    h5 {
        font-size: 15px;
        font-weight: 500;
        min-height: 30px;
        margin-bottom: 0;
        word-wrap: break-word;

    }

    img {
        width: 40px;
    }

    .beginner {
        font-size: 20px;
        // padding-bottom: 23px;
        font-weight: 500;

        @media screen and (max-width : 768px) {
            padding-bottom: 0;
            word-break: break-word;
        }
    }
}

.upcoming {
    background-color: var(--theme-color);
    padding: 10px 10px;

    @media screen and (max-width:568px) {
        margin: 0 0px;
    }

    h4 {
        margin: 0;
        font-size: 20px;
        color: var(--white-color);
        font-weight: 800;

        span {
            padding-left: 20px;
            color: var(--secondary);

            @media screen and (max-width:568px) {
                padding-left: 0px;
            }
        }
    }

    .upcoming-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--white-color);
        padding: 0 17px 10px 11px;

        @media screen and (max-width : 768px) {
            padding: 0 0 10px 0;
        }
    }

    .byo-data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 10px;

        @media screen and (max-width : 768px) {
            grid-template-columns: 1fr;
        }

        .reservation {
            padding-top: 10px;
            border-right: 2px solid var(--white-color);
            padding-left: 5px;
            text-align: left;

            @media screen and (max-width : 768px) {
                border-right: 0px solid;

            }

            &:first-child {
                @media screen and (max-width : 768px) {
                    border-bottom: 2px solid var(--white-color);
                }
            }

            &.remove-border-right {
                border-right: 0px solid;
                padding-left: 17px;
            }

            p {
                font-size: 13px;
                margin: 0;
                color: var(--white-color);
            }

            h4 {
                font-size: 17px;
                font-weight: 500;
                color: var(--white-color);
                margin: 0;
                padding: 10px 0;
            }

            h6 {
                font-size: 12px;
                font-weight: 400;
                font-style: italic;
                color: var(--white-color);
                margin: 0px 0 20px;
            }

            ul {
                padding: 0;
                list-style: none;
                display: inline-flex;
                flex-wrap: wrap;
                gap: 5px;


                li {
                    color: var(--white-color);

                    .fa {
                        padding-right: 5px;
                        color: var(--white-color);
                    }
                }
            }
        }
    }

}

.check-button {
    display: flex;
    justify-content: center !important;
}

.owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .owl-prev {
        font-size: 30px !important;
    }

    .owl-next {
        font-size: 30px !important;
    }

}

.carouser-items-list {
    .owl-nav {
        position: absolute;
        display: none;
        align-items: center;
        justify-content: space-between;
        bottom: 30px;
        width: 100%;


        .owl-prev {
            position: absolute;
            transform: rotate(180deg);
            bottom: 17px;
            left: 200px;

            span {
                font-size: 50px;
                color: var(--white-color);
            }
        }

        .owl-next {
            position: absolute;
            right: 20px;
            bottom: 26px;

            span {
                font-size: 50px;
                color: var(--white-color);
            }
        }
    }

    button.owl-dot {

        &.active {
            background-color: var(--theme-color) !important;
        }

    }

    button.owl-dot {
        border: 1px solid var(--theme-color);
    }

    .owl-dots {
        .owl-dot {
            padding: 10px;
            height: 10px;
            width: 10px;
            margin: 0 2px;
            border-radius: 50px;
        }
    }
}

.month-select {
    margin-bottom: 0;
    padding: 0;
    text-align: center;

    li {
        font-size: 23px;
        font-weight: 800;
        color: var(--secondary);
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;

        span {
            color: var(--theme-color);
            font-size: 50px;
            cursor: pointer;
        }

        .m-y-option {
            width: 220px;
        }
    }
}

.nav-pills .nav-link.active {
    color: var(--white-color) !important;
}

.pend {
    height: 60px;
}

.list-check {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .form-check {
        display: flex;
        // align-items: center;
        gap: 10px;
    }
}

.form-check-label {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.form-check-input {
    padding: 8px;
}

.card-items-review {
    background-color: var(--secondary);
    padding: 10px 20px;
    // border-radius: 10px;
    height: 100%;

    p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: var(--gray-color);
    }

    h5 {
        font-size: 15px;
        font-weight: 500;
        color: var(--white-color);
        margin: 0;
        line-height: 1.4;
    }
}

.modal-website {
    width: 600px;
    margin: 0 auto
}

.card-range {
    @extend .card-items-review;

}

.exterior {
    padding: 10px 0;

    img {
        width: 80px;
        height: 56px;
        // object-fit: cover;
    }
}

.midtown-bay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h4 {
        font-size: 17px;
        font-weight: 800;
        margin-bottom: 0;
        padding-left: 10px;

        @media screen and (max-width : 400px) {
            font-size: 14px;
        }
    }
}

.sto-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;


    @media screen and (max-width : 768px) {
        width: 100%;
    }

    h4 {
        font-size: 17px;
        font-weight: 800;
        margin-bottom: 0;
        padding-left: 10px;

        @media screen and (max-width : 400px) {
            font-size: 14px;
        }
    }
}

.create-box {
    text-align: left;
    // display: flex;
    // align-content: flex-start;
    // align-items: center;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));

    margin-top: 10px;
    gap: 10px;

    @media screen and (max-width:568px) {
        gap: 10px;
        column-gap: 10px;
        padding-bottom: 50px;
    }

    &__leans {
        border: 2px solid var(--theme-color);
        // flex: 1 0 150px;
        // width: 100%;
        // max-width: 150px;
        position: relative;
        padding: 10px;
        cursor: pointer;

        @media screen and (max-width : 768px) {
            max-width: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-top: 20px solid var(--theme-color);
            border-right: 20px solid transparent;
            transform: rotate(90deg);
            z-index: 1;
        }

        img {
            display: block;
            margin-left: auto;
            padding-right: 10px;
            width: 30px;

            @media screen and (max-width : 568px) {
                width: 40px;
            }

            @media screen and (max-width : 468px) {
                width: 30px;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 800;
            color: var(--secondary);
            margin-bottom: 0;
            padding: 15px 0;
        }

        p {
            font-size: 14px;
            color: var(--gray-color);
            font-weight: 500;
        }

        h5 {
            font-size: 16px;
            font-weight: 800;
            color: var(--secondary);
            margin-bottom: 0;
            padding: 15px 0;
        }

        h6 {
            font-size: 14px;
            color: var(--gray-color);
            font-weight: 500;
        }

    }

}

.table-width-plan {
    @media screen and (max-width : 768px) {
        width: 500px;
    }
}

.lane-name {
    font-size: 16px;
    font-weight: 800;
    color: var(--secondary);
    margin-bottom: 0;
}

.instock {
    color: var(--theme-color);
}

.delete {
    color: var(--danger);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-weight: 600;
}

.add-user-active {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    // position: absolute;

    @media screen and (max-width : 767px) {
        width: 100%;
        align-items: center;
        // justify-content: space-between;
        padding-right: 10px;
        padding-top: 10px;
        position: relative;
    }

    .booked {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .book-img {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: -3px;
                bottom: 0;
                width: 7px;
                height: 7px;
                border-radius: 50px;
                background-color: var(--theme-color);
                z-index: 1;
            }

            img {
                width: 18px;
            }

            &.book-bg-change {
                &::before {
                    background-color: var(--success);
                }
            }
        }

        p {
            margin-bottom: 0;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 800;

            span {
                font-weight: 500;
                padding-right: 10px;
            }
        }
    }
}

.category-search {
    background-color: var(--white-color);
    padding: 20px;
}

.clear-input {
    display: block;
    width: 100%;
    text-align: right;
    border: 0;
    background-color: transparent;
    text-decoration: underline;

}

.cline-icon {

    .fa-solid {
        color: green;

        &:hover {
            color: red;
            display: block;
            // font-size: 50px;
        }
    }
}

.user-n-2 {
    display: flex !important;
    align-items: center;
    gap: 5px;
    flex-direction: column;

    // .box-bg {
    //     flex: 0 0 45px;
    // }

    @media screen and (max-width : 768px) {
        display: none !important;
    }
}

.listing-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button-range-create {
    display: flex;
    gap: 20px;
}

@media screen and (max-width : 768px) {
    .button-range-create a {
        flex: 1;
    }
}

.features {
    label {
        padding-left: 5px;
    }
}

.gun-types {
    @extend .features;
}

.club {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
        width: 17px;
        height: 17px;
        background-color: #534582;
        display: inline-block;
    }
}

.premium {
    @extend .club;

    span {

        background-color: #A40000;

    }
}

.partner {
    @extend .club;

    span {

        background-color: #337AB7;

    }
}

.participating {
    @extend .club;

    span {

        background-color: #fff;
        border: 1px solid #000;

    }
}

.timer {
    .modal-body {
        padding: 0;
    }

    .hours {
        padding: 1rem;
    }
}

.social-h {
    label {
        font-weight: 600;
    }
}

.link-tag {
    font-size: 15.6px;
    color: #0d6efd;
    text-decoration: underline;

    &:hover {
        color: var(--theme-color);
    }
}

@media screen and (min-width : 769px) {
    .edit-link {
        justify-content: flex-end !important;
    }
}

.page-not-found {
    display: flex;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media screen and (max-width : 568px) {
        height: calc(100vh - 425px);
    }

    justify-content: center;

    img {
        margin-bottom: 10px;
        display: block;
    }


    h4 {
        font-size: 23px;
        font-weight: 500;
        color: var(--secondary);
    }

    p {
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary);
    }
}

.set-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.card-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.questions {
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (max-width : 668px) {
        flex-wrap: wrap;
        row-gap: 0;
        justify-content: flex-end;
    }

    .q-n {
        width: 100%;
    }
}

.enter-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        width: 100%;
    }
}

.options-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width : 668px) {
        margin-bottom: 20px;
        justify-content: flex-start;
    }
}

.link-firearm {
    text-align: left !important;
    display: block;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
    color: #0d6efd !important;
    margin-top: 10px;
}

.img-logo-3 {
    padding: 10px 20px !important;
}

.oo {
    a {
        div {
            button {
                padding: 8px 30px !important;
            }
        }
    }
}

.qu {
    font-size: 20px;
    font-weight: 600;
    text-align: left !important;
    color: var(--secondary);
}

.dd {
    width: 20px;
}

.d-img-c {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 50px;
    z-index: 1;
}

.ddd-card {
    width: 30px;
    height: 20px !important;
}

.bb {
    object-fit: fill !important;
}

.fir-m-c {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    aspect-ratio: 1 / 1;
    position: relative;

    // &::before {
    //     content: '\00d7';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(0, 0, 0, 0.5);
    //     color: #fff;
    //     font-size: 30px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;
    // }

    img {
        width: 100%;
        height: 100%;
    }
}

.qq-user {
    border: 1px solid var(--theme-color);
    padding: 10px;
    text-align: left;


    .h-e-d {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;


        .q-h {
            font-size: 20px;
            font-weight: 600;
            color: var(--secondary);
            margin-bottom: 0;
        }

        .qu-edit-det {
            display: flex;
            gap: 10px;

            img {
                width: 25px;
                cursor: pointer;
            }
        }
    }

    .option-cc {
        font-size: 18px;
        color: var(--secondary);
        font-weight: 500;
    }

    ol {
        padding: 0 0 0 18px;

        li {
            font-size: 16px;
            margin-bottom: 0;
            padding-bottom: 5px;
            color: var(--secondary);
        }
    }

}

.q-r-n {
    input {
        border-radius: 50px !important;
    }
}

.valid {
    width: 100px !important;
    flex: 0 0 100px !important;
    margin: 0 10px 34px;

    @media screen and (max-width : 668px) {
        flex: 0 0 30px !important;
    }

    .form-check.q-r-n {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
}

.qqq-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    @media screen and (min-width : 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.ww {
    @media screen and (max-width : 668px) {
        width: 79%;
    }
}

.ww-2 {
    @media screen and (max-width : 668px) {
        width: 100%;
    }
}

.close-firearm-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    aspect-ratio: 1 / 1;
    background-color: var(--input-bg);
    font-size: 19.8px;
    font-weight: 600;
    color: var(--secondary);
    padding: 5px;

    img {
        width: 100%;
        height: 100%;
    }
}

.image-gg {
    width: 80px;
    aspect-ratio: 1 / 1;

    img {
        width: 100%;
        aspect-ratio: 1 / 1;
    }

    @media screen and (max-width : 768px) {
        width: 100%;
        height: 100%;
    }
}

.table-w-set {
    @media screen and (min-width : 768px) {
        width: 380px;
    }
}


.change-bay {
    a {
        display: flex;
        align-items: center;

        span {
            font-size: 16px;
            font-weight: 500;
            color: var(--secondary);
        }

        .fa {
            font-size: 20px;
            font-weight: 600;
            color: var(--theme-color);
        }

        &:hover span {
            color: var(--theme-color);
        }
    }
}


.edit-hover-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 3px;
    top: -22px;
    z-index: 2;
    visibility: hidden;

    &:hover {
        visibility: visible;
    }

    img {
        width: 30px !important;
        height: 30px !important;
    }
}

.box-bg:hover .edit-hover-image {
    visibility: visible;
}

.drop-bg-color {
    background-color: var(--secondary);
    padding: 21.3px 0 !important;

}

.create-slots {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    @media screen and (max-width : 768px) {
        flex-direction: column;

        row-gap: 30px;
    }

    .tickets {
        flex: 1;
        margin-bottom: 0;

        input {
            margin-bottom: 0;

        }

    }

    .delete-slots {
        img {
            width: 25px;
        }
    }

    &.book-slots {
        max-width: 100%;
        margin: 30px auto 10px;
        justify-content: space-between;

        @media screen and (max-width : 992px) {
            max-width: 100%;
            width: 100%;
            flex-direction: column;
        }

        .select-bays {
            display: flex;
            flex: 1 0 50%;
            gap: 15px;

            @media screen and (max-width : 993px) {
                flex: 1 0 100%;
                flex-direction: column;
                width: 100%;
            }

            .select-input {
                width: 100%;
            }
        }

        .add-guest-price {
            display: flex;
            flex-direction: column;
            flex: 1 0 50%;
            justify-content: flex-end;
            gap: 15px;

            p {
                color: var(--secondary);
                margin-bottom: 0;
                font-size: 17px;
                font-weight: 500;
                text-align: right;
            }

            @media screen and (max-width : 993px) {
                flex: 1 0 100%;
            }
        }
    }
}


.user-count-select {
    margin-top: 20px;

    .user-box {
        display: flex;
        border: 1px solid var(--input-bg);
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 5px;


        p {
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 600;
            color: var(--secondary);
        }

        .cr-s-i {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--input-bg);
            padding: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.r-b-m {
    width: 320px;
    overflow: hidden;
}

.disabled-c {
    color: var(--gray-color) !important;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.disabled-slot-option {
    color: var(--gray-color) !important;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    background-color: var(--input-bg)
}

.change-bay {
    position: relative;
    z-index: 4;
}

.payment-card {
    border-bottom: 0px solid transparent !important;
    max-width: 700px;
    display: flex;
    justify-content: center;
    gap: 10px;
    row-gap: 20px;
    margin: 0 auto;

    li {
        background-color: #fff;

        .custom-action-button {


            button {
                background-color: #fff;
                border-bottom: 1px solid var(--theme-color);

                &:hover {
                    border-bottom: 1px solid transparent;
                    border-top: 1px solid transparent;
                    background-color: var(--theme-color);
                    color: var(--white-color);
                }

                span {
                    img {
                        width: 30px !important;
                    }
                }

                &.active {
                    border-color: transparent !important;
                    background-color: var(--theme-color) !important;
                    color: var(--white-color) !important;

                    span {
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }
        }


    }
}

.card-payment-ab {
    width: 500px;
    margin: 0 auto;

    .tickets {
        height: auto;
    }

    @media screen and (max-width : 768px) {
        max-width: 100%;
    }
}



.back-listing-tab {
    display: flex;
    align-items: center;

    a {
        transform: translate(-20px, 21px);

        @media screen and (max-width : 769px) {
            // transform: translate(0, 0);
            width: 59px;
        }

        @media screen and (max-width : 569px) {
            display: none !important;
        }



        div {
            button {
                padding: 10px 16px;

                @media screen and (max-width : 769px) {
                    width: 55px;
                }

                img {
                    margin-right: 0;
                    width: 24px !important;
                }
            }
        }
    }
}

.show-bbb {
    @media screen and (max-width : 569px) {
        display: block !important;
    }
}

.new-back-btn-add {
    flex-wrap: nowrap !important;

    .b-p {
        display: flex !important;
        transform: translate(-20px, 13px);

        @media screen and (max-width : 569px) {
            transform: translate(-20px, 11px);
            width: 45px;
        }

        div {
            button {
                padding: 9px 16px;

                img {
                    margin-right: 0;
                }
            }
        }

        @media screen and (min-width : 570px) {
            display: none !important;
        }


    }

    .new-cum {
        @media screen and (min-width : 767px) {
            display: none !important;
        }
    }

}