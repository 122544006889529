// new table css start

.table-width-few-td {
    @media screen and (min-width : 769px) {
        width: 140px;
        text-align: center;
        margin: 0 auto;
        display: block;
    }

    &.new-wt {
        @media screen and (max-width : 768px) {
            width: 120px;

        }
    }
}

td {
    p {
        word-break: break-all
    }

    a {
        word-break: break-all
    }
}

// .font-s {
//     font-size: 20px;

//     @media screen and (max-width : 768px) {
//         font-size: 15px;
//     }
// }

.table-button-width {
    width: 160px;
    // width: 100%;
    word-wrap: break-word;
}

.w-t-b {
    display: block;
    margin: 10px auto;
    width: 160px !important;

    @media screen and (max-width : 768px) {
        width: 100% !important;
    }

}

.border-theme {
    @media screen and (max-width : 768px) {
        border: 2px solid var(--theme-color);
        padding: 8px 10px;
    }
}

.border-red {
    @media screen and (max-width : 768px) {
        border: 2px solid var(--danger);
        padding: 8px 10px;
    }
}

.border-green {
    @media screen and (max-width : 768px) {
        border: 2px solid var(--green-color);
        padding: 8px 10px;
    }
}

.w-a {
    @media screen and (max-width : 768px) {
        width: 140px;
        display: block;
        word-wrap: break-word;
    }
}

.table-user-gan {
    border-collapse: separate;
    border-spacing: 0 10px;
    vertical-align: middle;

    &.table-changes-border {
        tbody {
            td:first-child {
                border: 0px !important;

            }

            td:nth-child(2) {
                border-left: 2px solid var(--theme-color) !important;

                @media screen and (max-width : 768px) {
                    border-left: 0 !important;
                }
            }

        }
    }

    &.table-changes-border-3 {
        thead {
            th {
                border-bottom: 2px solid var(--theme-color) !important;


            }
        }

        tbody {
            td:first-child {
                border: 0px !important;

            }

            td:nth-child(2) {
                // border: 0px !important;

            }

            td:nth-child(2) {
                border-left: 2px solid var(--theme-color) !important;

                @media screen and (max-width : 768px) {
                    border-left: 0 !important;
                }
            }

        }
    }

    &.table-blocker {
        thead {
            th {
                border-bottom: 2px solid var(--theme-color) !important;
                white-space: nowrap;
                text-align: left;
            }
        }

        tbody {
            td {
                border: 0px !important;
                text-align: left;
                color: var(--secondary);

                &.bg-color-red {
                    background-color: var(--danger);
                    cursor: not-allowed;
                }

                &.bg-color-selected {
                    background-color: #EA7B44;
                    cursor: pointer;
                }

                &.bg-color-theme {
                    background-color: var(--input-bg);
                    cursor: pointer;
                }
            }

            td:first-child {
                border-left: 0px !important;
            }

            td:last-child {
                border-right: 0px !important;

            }

        }
    }

    thead {
        th {
            border-bottom: 0px solid var(--theme-color) !important;
            white-space: nowrap;
        }
    }

    tbody {
        td {
            border: 2px solid var(--theme-color) !important;
            border-left: 0px !important;
            border-right: 0px !important;

            .img-set-gun-left {
                display: block;
                margin-left: auto;
            }

            .img-set-gun-right {
                display: block;
                margin-right: auto;
            }

            .img-set-gun-center {
                display: block;
                margin: 0 auto;
            }

            @media screen and (max-width : 768px) {
                border: 0 !important;
            }
        }



        td:first-child {
            border-left: 2px solid var(--theme-color) !important;

            @media screen and (max-width : 768px) {
                border-left: 0 !important;
            }
        }

        td:nth-child(2) {

            border-left: 2px solid var(--theme-color) !important;

            @media screen and (max-width : 768px) {
                border-left: 0 !important;
            }
        }

        td:last-child {
            border-right: 2px solid var(--theme-color) !important;

            @media screen and (max-width : 768px) {
                border-right: 0 !important;
            }
        }
    }

    .exterior {
        width: 120px;
        flex: 0 0 120px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width : 768px) {
            width: 100%;
            height: 180px;
        }
    }

    &.table-completed {
        tbody {
            td {
                padding: 15px 10px;

                @media screen and (max-width : 768px) {
                    padding: 15px 0;
                }
            }
        }
    }
}

.r-n-img {
    display: flex;
    align-items: center;
    // width: 300px;
    gap: 10px;
    justify-content: flex-start;

    @media screen and (max-width : 768px) {
        width: 100%;
        padding-right: 10px;
        flex-direction: column;
        align-items: flex-start;
    }

    p {
        font-size: 17px;
        font-weight: 500;
        color: var(--secondary);
        text-align: left;
        flex: 1;
    }
}

.time-xx {
    max-width: 180px;

    @media screen and (max-width : 768px) {
        max-width: 100%;
    }
}

.r-n-img-2 {
    width: 200px;

    @media screen and (max-width : 768px) {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }
}

p {
    margin-bottom: 0;
}

.head-dote {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        padding-right: 15px;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0px;
        top: 5px;
        width: 10px;
        height: 10px;
        background-color: var(--danger);
        border-radius: 50px;
    }

    &.dote-color-change {
        &::before {
            background-color: var(--success);
        }
    }
}

.color-black {
    color: var(--secondary);
}

.color-red {
    color: var(--danger);
}

.color-theme {
    color: var(--theme-color) !important;
}

.color-green {
    color: var(--green-color);
}

.color-gray {
    color: var(--gray-color);
}

.border-text {
    text-decoration: underline;
}

.color-white {
    color: var(--white-color);
}

.cursor-hover {
    cursor: pointer;
}

.modal-question {
    list-style: none;
    padding: 0;

    p {
        font-size: 16px;
        display: flex;

        span {
            padding-right: 10px;
            font-weight: 600;
            flex: 0 0 80px;
        }
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.center-left {
    @media screen and (max-width : 768px) {
        text-align: left !important;
    }
}

.bg-color-red {
    background-color: var(--danger);
    padding: 7px;
}

.color-black-fw {
    color: var(--secondary);
    font-weight: 600;
    padding-top: 5px;
}

.bg-color-theme {
    background-color: var(--theme-color);
    padding: 7px;
}

.gun-customer {
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: center;

    @media screen and (max-width : 768px) {
        justify-content: flex-start;
    }

    .gun-check-out {
        img {
            width: 130px;
        }
    }
}

.text-left-mob {
    @media screen and (max-width : 768px) {
        text-align: left;
        white-space: nowrap;
    }
}

//  old and even css start
@media (max-width: 768px) {
    .mobile-responsive thead {
        display: none;
    }

    .mobile-responsive,
    .mobile-responsive tbody,
    .mobile-responsive tr,
    .mobile-responsive td {
        display: block;
        width: 100%;

    }

    .mobile-responsive tr {
        margin-bottom: 15px;

    }

    .mobile-responsive td {
        padding-left: 50%;
        text-align: left;
        position: relative;
        color: var(--secondary);

        &.td-left {
            padding-left: 13px;
        }
    }

    .time-o-c td {
        padding-left: 38%;
    }

    .up td:first-child {
        padding-left: 8px !important;
    }



    .mobile-responsive td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 46%;
        padding-left: 8px;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: var(--background) !important;
        font-weight: 600;
        text-transform: uppercase;

    }
}

@media (max-width: 992px) {
    .time-select-2 thead {
        display: none;
    }

    .time-select-2,
    .time-select-2 tbody,
    .time-select-2 tr,
    .time-select-2 td {
        display: block;
        width: 100%;

    }

    .time-select-2 tr {
        margin-bottom: 15px;

    }

    .time-select-2 td {
        padding-left: 50%;
        text-align: left;
        position: relative;
        color: var(--secondary);

        &.td-left {
            padding-left: 13px;
        }
    }

    // .time-o-c td {
    //     padding-left: 38%;
    // }

    .up td:first-child {
        padding-left: 8px !important;
    }



    .time-select-2 td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 46%;
        padding-left: 8px;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: var(--background) !important;
        font-weight: 600;
        text-transform: uppercase;

    }
}




.mobile {

    @media screen and (max-width : 768px) {
        border-collapse: separate;
        border-spacing: 0 10px !important;
        vertical-align: middle;

        &__old-even {

            tr:nth-child(odd) {
                background-color: var(--even-color);
            }

            tr:nth-child(even) {
                background-color: var(--white-color);
            }
        }

        &__res-card-2 {

            tr:nth-child(odd) {
                border: 2px solid var(--theme-color);
                background-color: transparent;
                // margin-bottom: 20px;
            }

            tr:nth-child(even) {
                border: 2px solid var(--theme-color);
                background-color: transparent;
                margin-bottom: 20px;
            }

        }

    }

}

.time-select {

    .table-time-gun-create__old-even {
        tr:nth-child(odd) {
            .tickets {
                position: relative;

                &::before {
                    content: '';
                    border-bottom: 14px solid var(--even-color);
                    border-left: 49px solid transparent;

                }
            }

            input {
                background-color: var(--white-color);
            }

            .form-select {
                background-color: var(--white-color);
            }
        }
    }

}

.table-time-gun-create {
    border-collapse: separate;
    border-spacing: 0 10px !important;
    vertical-align: middle;

    p {
        margin-bottom: 0;
        word-break: break-word;
        // width: 180px;
    }

    thead {
        white-space: nowrap;

        th {
            border-bottom: 2px solid var(--theme-color) !important;
        }


    }

    tbody {
        td {
            white-space: wrap;
        }
    }

    &__old-even {
        th {
            border-bottom: 0 !important;
        }

        td {
            border-bottom: 0 !important;
        }

        tr:nth-child(odd) {
            background-color: var(--even-color);

            @media screen and (max-width : 992px) {
                padding: 20px;
            }

            @media screen and (max-width : 568px) {
                padding: 0px;
            }

        }

        tr:nth-child(even) {
            background-color: var(--white-color);

            @media screen and (max-width : 768px) {
                padding: 20px;
            }

            @media screen and (max-width : 568px) {
                padding: 0px;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                font-size: 14px;
            }
        }

        &.user-all-plan-select {
            th {
                width: 180px;
                text-transform: capitalize !important;

                @media screen and (max-width : 768px) {
                    width: 180px;
                    font-size: 13px;
                }

            }

            td {
                @media screen and (max-width : 500px) {
                    display: block;
                    width: 150px;
                    margin-left: auto;
                    word-break: break-all;
                }
            }
        }

    }

    .size-cover-check-box {
        width: 20px;
        margin-right: 4px;
    }

    .car-ser {
        cursor: pointer;
    }

    &__padding {
        td {
            padding: 20px 0;
        }
    }

    .edit-user-id {
        display: flex;
        gap: 2px;
        justify-content: center;
        column-gap: 10px;

        @media screen and (max-width : 768px) {
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;
        }

        a {
            display: flex;
            align-items: center;
            gap: 3px;

            img {
                width: 20px;
            }
        }
    }

    .delete-button {
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
}

.create-card {
    border-collapse: separate;
    border-spacing: 0 0px !important;
    margin-bottom: 0;
    border: 2px solid var(--theme-color);
    border-bottom: 1px solid var(--theme-color);

    @media screen and (max-width : 768px) {
        border-bottom: 2px solid var(--theme-color);
    }

    thead {
        th {
            background-color: var(--even-color);
            color: var(--secondary);
        }
    }

    tbody {
        tr {
            margin-bottom: 0 !important;
            border-bottom: 2px solid var(--theme-color) !important;

            &:last-child {

                @media screen and (max-width : 768px) {
                    border-bottom: 0px !important;
                }
            }
        }

        td {
            // border: 0px;
            background-color: var(--even-color);
            border-bottom: 1px solid var(--theme-color) !important;
            vertical-align: middle;

            @media screen and (max-width : 768px) {
                border-bottom: 0px !important;
            }
        }
    }

    .table-list {
        background-color: var(--theme-color) !important;
    }

    &.cerate-card-2 {
        thead {
            th {
                background-color: var(--white-color);

            }
        }

        tbody {
            td {

                background-color: var(--white-color);

            }

            tr {
                margin-bottom: 0 !important;
                border-bottom: 2px solid var(--theme-color) !important;

                &:last-child {

                    @media screen and (max-width : 768px) {
                        border-bottom: 0px !important;
                    }
                }
            }
        }
    }
}

.table-list .head-left {
    text-align: left;
}

.create-plan {
    border-bottom: 1px solid transparent;



    th {
        text-align: left;
        padding: 10px;
        white-space: nowrap;
        // width: 230px;
        text-transform: capitalize !important;

    }

    td {
        text-align: left;

        ul {
            list-style-type: disc !important;
        }
    }

}

.t-b-t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;
    gap: 10px;
    padding-right: 10px;

    @media screen and (max-width : 468px) {
        width: 120px;
        white-space: break-spaces;
        padding-right: 10px;
    }
}

.vv {
    @media screen and (max-width : 668px) {
        vertical-align: baseline;
    }
}

// old and even css end
progress[value] {
    --color: #EA7B44;
    --background: #919090;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 200px;
    margin: 0 10px 0 0;
    border-radius: 0;
    background: var(--background);
}

progress[value]::-webkit-progress-bar {
    border-radius: 0;
    background: var(--background);
}

progress[value]::-webkit-progress-value {
    border-radius: 0;
    background: var(--color);
}

progress[value]::-moz-progress-bar {
    border-radius: 0;
    background: var(--color);
}

.label {
    font-size: 15px;
    font-weight: bold;
    display: flex;
    margin: 20px 0;
    align-items: center;

    @media screen and (max-width : 768px) {
        margin-top: 0;
    }
}

// new table css end

.user-table-id {
    vertical-align: middle;

    tbody {
        @media screen and (min-width : 768px) {
            border: 2px solid var(--theme-color);
        }
    }
}

.user-table-id>:not(caption)>*>* {
    @media screen and (max-width : 768px) {
        border-bottom-width: 0px !important;
    }
}

.table-w-d {
    tbody {
        td {
            width: 25%;

            // @media screen and (max-width : 992px) {
            //     width: 100%;
            // }
            @media screen and (max-width : 768px) {
                width: 100%;
            }

            .user-n {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 20px;
                gap: 10px;
                flex-direction: column;

                @media screen and (max-width : 768px) {
                    justify-content: flex-start;
                    display: none !important;
                }
            }
        }
    }
}

.range-width {
    width: 400px;

    @media screen and (max-width : 768px) {
        width: 100%;
    }
}