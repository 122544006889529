:root {
    --clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.box-bg {
    background-color: var(--theme-color);
    clip-path: var(--clip-path);
    width: 45px;
    height: 50px;
    padding: 2px;

    &.user-outer-line-theme {
        background-color: var(--white-color);
    }

    &.bg-black {
        background-color: var(--secondary);
        width: 125px;
        height: 145px;
        padding: 3px;

        &.bg-success {
            background-color: var(--success) !important;
        }


    }

    &.set-images {
        flex: 0 0 45px;
    }


    &.bg-org {
        background-color: var(--theme-color);
    }

    .bg-hox {
        background-color: var(--white-color);
        width: 100%;
        clip-path: var(--clip-path);
        height: 100%;
        padding: 3px;

        &.bg-width-outline {
            height: 100%;
            padding: 4px;

        }

        &.bg-trans {
            background-color: var(--bg-gray);
        }

    }

    .img-size {
        width: 100% !important;
        height: 100% !important;
        clip-path: var(--clip-path);
        object-fit: cover;


    }
}

.box-bg-2 {
    background-color: var(--white-color);
    clip-path: var(--clip-path);
    width: 45px;
    height: 50px;
    padding: 2px;

    &.bg-bo-li-th {
        background-color: var(--theme-color);
        width: 40px;
        height: 45px;
    }

    &.bg-bo-li-th-w {
        background-color: var(--theme-color);
        width: 125px;
        height: 145px;
        padding: 3px;
    }

    .bg-hox-2 {
        background-color: var(--secondary);
        width: 100%;
        clip-path: var(--clip-path);
        height: 100%;
        padding: 6px 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.bg-line-p {
            padding: 9px 3px;
        }

        &.pad {
            padding: 7px;
        }

        &.bg-dark-bc {
            background-color: var(--bg-gray);
            cursor: pointer;

            &:hover {
                background-color: var(--theme-color);

            }

            &:hover img {
                filter: brightness(0) invert(1);
            }
        }

        &.pd {
            padding: 3px;
        }

        &.pd-cover {
            padding: 40px 25px;
        }

    }

    .img-size-2 {
        width: 20px;
        height: 100%;
        clip-path: var(--clip-path);

        &.size-cover-2 {
            width: 30px !important;
        }

        &.today-size {
            width: 100%;
        }
    }
}

.clip {
    clip-path: none !important;
}

.pro-hex {
    width: 35px;
    height: 40px;
    background-color: var(--theme-color);
    clip-path: var(--clip-path);
    padding: 2px;

    .bg-gray {
        background-color: var(--bg-gray);
        width: 100%;
        height: 100%;
        clip-path: var(--clip-path);
        display: flex;
        align-items: center;
        justify-content: center;

        &.bg-white-progress {
            background-color: var(--white-color);
        }

        &.bg-active {
            background-color: var(--theme-color);
            color: var(--white-color);
            font-size: 20px;
            font-weight: 800;
        }
    }

    // bg color user detail fill hone kye bad ye wali classes use karni hai

    .parrot {
        background-color: var(--success);
        width: 100%;
        height: 100%;
        clip-path: var(--clip-path);
    }

    .danger {
        background-color: var(--danger);
        width: 100%;
        height: 100%;
        clip-path: var(--clip-path);
    }

}

.bg-black {
    background-color: var(--secondary);
    height: 100%;
    width: 100%;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    padding: 5px;
}

.gun-bg {
    background-color: var(--secondary);
    display: flex;
    gap: 20px;
    padding: 20px 15px;
    align-items: center;
    justify-content: space-between;

    .practice-gun {
        // width: 60px;
        // height: 35px;
        display: flex;
        align-items: center;
        justify-content: flex-start;


        img {
            width: 100% !important;
            height: 100% !important;
        }
    }
}