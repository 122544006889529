.ck.ck-toolbar {
    background-color: #EAEAEA !important;
    border: 0 !important;
}

.ck.ck-toolbar>.ck-toolbar__items {
    flex-grow: 0 !important;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: 0px !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background-color: #EAEAEA !important;
}

.ck.ck-editor__main>.ck-editor__editable:focus {
    outline: 0;
    box-shadow: none;
    border-color: transparent;
}

.ck.ck-editor__editable_inline {
    border: 0px;
    border-top: 1px solid #fff !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    outline: 0 !important;
    box-shadow: none !important;
}