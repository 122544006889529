.theme-heading {
    font-family: 'Gotham';
    font-size: 5vw;
    margin-bottom: 0;
    font-weight: 800;
    display: block;

    &.theme-sub-heading {
        font-weight: 400;
    }
}

.sub-heading {
    font-size: 20px;
    font-family: 'Gotham';
    font-weight: 800;
    display: block;
    margin-bottom: 0;
    letter-spacing: 0;
    color: var(--theme-color);
    text-transform: uppercase;

    &.heading {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }


    &.heading-color {
        color: var(--white-color);
    }

    &.heading-color-changes {
        color: var(--secondary);
        text-transform: capitalize;
    }
}

.simple-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;

    &.theme-color {
        color: var(--theme-color);
    }
}


.tab-sub-heading {
    font-size: 17px;
    font-style: italic;
    margin-bottom: 0;
}