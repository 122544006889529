.card-create {
    box-shadow: none;
    border: 1px solid transparent
}

.card-body {
    position: relative;
    padding: 0;

    h3 {
        padding-left: 20px;
        transform: translateY(20px);
        font-size: 20px;
        font-weight: 700;
        color: var(--theme-color);
        margin-bottom: 0;
        text-transform: uppercase;



        // &.card-top {
        //     transform: translateY(6px);
        //     padding-left: 0;
        // }

        &.card-top-2 {
            // transform: translateY(18px);
            padding-left: 0;

            @media screen and (min-width: 768px) {
                flex: 0 0 410px;
            }

        }

        &.range-a {
            @media screen and (min-width: 768px) {
                flex: 0 0 240px;
            }
        }
    }

    .h-set-mob {
        @media screen and (max-width : 969px) {
            width: 300px;
        }

        @media screen and (max-width : 569px) {
            transform: translate(50px, 56px) !important;
            width: 279px;
        }

    }

    .h-set-2 {
        @media screen and (max-width : 969px) {
            width: 300px;
        }

        @media screen and (max-width : 569px) {
            transform: translate(50px, 76px) !important;
            width: 100%;
        }

    }


    &.card-overflow {
        overflow-x: auto;
    }
}

.body-text {
    border: 2px solid var(--theme-color);
    position: relative;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-radius: 0px;
    margin-top: 20px;
    // padding: 15px 24px 20px 5px;
    padding: 15px 25px 250px 5px;
    text-align: center;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: var(--theme-color);

    }


    @media screen and (max-width:767px) {
        padding: 15px 12px 400px 5px;
    }

    &.ins-card {
        @media screen and (max-width:567px) {
            padding: 15px 25px 250px 5px;
        }
    }

    &.up-qq {
        padding: 15px 10px 50px 5px;

        @media screen and (max-width:767px) {
            padding: 15px 10px 550px 5px;
        }
    }

    &.create-padding {
        @media screen and (max-width: 768px) {
            padding: 15px 5px 450px 5px;
        }
    }

    &.add-pro {
        @media screen and (max-width: 468px) {
            padding: 15px 5px 200px 5px;
        }
    }

    &.body-p-b {
        min-height: auto;
        max-height: auto;
        overflow-x: hidden;
        padding-bottom: 30px;

        @media screen and (max-width:768px) {
            padding: 15px 12px 20px 10px !important;
        }
    }

    &.scrolling {
        min-height: 350px;
        max-height: 350px;
        overflow-y: scroll;
        position: relative;
        padding-bottom: 20px;


        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: var(--theme-color);

        }

    }

    &.experience-add {
        @media screen and (max-width:767px) {
            padding: 15px 12px 440px 5px;
        }
    }

    &.height-card {
        height: calc(100vh - 142px) !important;
    }

    &.range-ac {
        @media screen and (max-width:569px) {
            padding: 15px 12px 450px 5px;
        }
    }

    &.booking-ac {
        @media screen and (max-width:569px) {
            padding: 15px 5px 450px 5px;
        }
    }

    &.padding-right {
        padding-right: 30px;

        @media screen and (max-width: 768px) {
            padding-right: 20px;
        }
    }

    &.margin-top {
        margin-top: 0;
    }

    &.body-font {
        p {

            min-height: 60px;
            margin: 0 auto;
            padding: 0 30px;
            font-size: 1.3vw;
            display: block;
            font-weight: 500;
            color: var(--theme-color);

            @media screen and (max-width:822px) {
                min-height: 22px;
                max-height: 60px;
            }

            @media screen and (max-width : 768px) {
                font-size: 15px;
                padding: 0;
                min-height: 40px;
            }
        }

        h4 {
            font-size: 60px;
            margin-bottom: 0;
            font-weight: 300;
            display: block;
            color: var(--secondary);

            @media screen and (max-width : 768px) {
                padding-bottom: 10px;
            }

        }
    }

    &.body-listing {
        text-align: left;

        ul {
            padding: 0;
            list-style: none;

            li {
                cursor: pointer;
                padding: 10px;

                &:hover {
                    background-color: rgb(207, 207, 207);
                    padding: 10px;
                }

                &:odd {
                    background-color: var(--white-color);
                }

                &:even {
                    background-color: var(--even-color);
                }

                &:nth-child(odd) {
                    background-color: var(--white-color);
                }

                &:nth-child(even) {
                    background-color: var(--even-color);
                }



                h4 {

                    font-size: 20px;
                    display: flex;
                    color: var(--secondary);
                    font-weight: 800;

                    @media screen and (max-width : 768px) {
                        font-size: 17px;
                    }

                    span {
                        display: block;
                        text-align: right;
                        flex: 1
                    }

                }

                p {

                    font-size: 14px;
                    color: var(--secondary);
                    display: flex;
                    font-style: italic;
                    margin-bottom: 0;

                    span {
                        display: block;
                        text-align: right;
                        flex: 1;
                    }

                }



            }
        }
    }

    &.book-slot-w {


        @media screen and (max-width : 992px) {
            padding: 15px 5px 200px 5px;
        }
    }
}

.triangle-top {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 25px solid var(--theme-color);
        border-right: 16px solid transparent;
        visibility: visible;
    }

    &.changes-top {
        &::before {
            content: '';
            position: absolute;
            left: -5px;
            transform: translateY(-2px);
        }
    }
}