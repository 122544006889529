.pagination-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width : 670px) {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        row-gap: 15px;
        border-top: 2px solid var(--theme-color);
        padding-top: 15px;
    }

    p {
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 500;
        color: var(--theme-color);
    }

    .pagination {
        position: relative;
        z-index: 0;

        .disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .page-item {
            a {
                color: var(--theme-color) !important;

                &:hover {
                    background-color: var(--theme-color);
                    border-color: var(--theme-color) !important;
                    color: var(--white-color) !important;
                }

                &.disable-text {
                    color: #6c757d !important;
                }
            }
        }

        .page-item.active .page-link {
            background-color: var(--theme-color) !important;
            border-color: var(--theme-color);
            color: var(--white-color) !important;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
}

.pages-pagination {
    display: flex;
    align-items: center;
    gap: 10px;

    .tickets {
        .form-select {
            color: var(--theme-color) !important;
        }
    }
}