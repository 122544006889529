:root {
    // --clip-input: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
}

.label-heading {
    text-align: left !important;
    font-size: 15px;
    font-weight: 500;
    display: block;
    color: var(--secondary);
    padding-bottom: 5px;
    margin-bottom: 0;

    &.c-t-f {
        font-size: 17px;
        color: var(--white-color);
    }
}

.input-search-items {
    position: relative;

    img {
        position: absolute;
        right: 20px;
        top: 15px;
        width: 17px;
    }
}

.input-theme {
    background-color: var(--input-bg);
    color: var(--secondary);
    font-size: 16px;
    font-weight: 500;
    clip-path: var(--clip-input);
    border-radius: 0;
    padding: 10px 40px 10px 10px;
    border-color: transparent;
    // margin-bottom: 20px;
    position: relative;

    &.input-pd {
        padding: 10px 20px 10px 10px;
    }

    &:focus {
        background-color: var(--input-bg);
        outline: 2px;
        box-shadow: none;
        border-color: transparent;
    }
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url("../../../public/assets/icon/cross_icon.svg") no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: 0.9;
    pointer-events: all;
}

.date-input {
    position: relative;
    // width: 200px;
    cursor: pointer;

    img {
        width: 20px;
        position: absolute;
        top: 12px;
        left: 10px;
        z-index: 1;
    }

    input {
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary);
        padding-left: 40px;
        background-color: var(--input-bg);
        clip-path: var(--clip-input);
        cursor: pointer;
        margin-bottom: 20px;
        padding: 10px 20px 10px 10px;
        border: 0;
        border-radius: 0;
        white-space: nowrap;

        &:focus {
            box-shadow: none;
            outline: 0px;
            border-color: var(--input-bg);
            background-color: var(--input-bg);
        }
    }
}

.events-time {
    .select-time {
        display: flex;
        gap: 5px;
    }
}

.form-select {
    background-position: right 1.75rem center;
}

.tickets {
    position: relative;
    height: 100%;
    padding-bottom: 15px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    &::before {
        z-index: 1;
        transform: rotate(-90deg);
        content: "";
        position: absolute;
        right: -18px;
        top: 15px;
        width: 0;
        height: 0;
        border-bottom: 14px solid var(--white-color);
        border-left: 49px solid transparent;
    }

    &.tag-col-change {
        position: relative;

        &::before {
            border-bottom: 14px solid var(--theme-color);
        }
    }

    .form-select {
        @media screen and (max-width: 400px) {
            background-position: right 0.9rem center !important;
        }
    }
}

.multiselect-container {
    position: relative;
    height: 100%;

    &::before {
        z-index: 1;
        content: "";
        position: absolute;
        right: -2px;
        top: -1px;
        width: 40px;
        height: 100%;
        background-color: var(--white-color);
        clip-path: polygon(100% 0, 70% 0%, 100% 105%);
        padding-left: 30px;
    }

    &.tag-col-change {
        position: relative;

        &::before {
            border-bottom: 14px solid var(--secondary);
        }
    }

    .optionContainer {
        border-radius: 0;

        .highlight {
            background-color: var(--theme-color);
            color: var(--white-color);
        }

        li {
            &:hover {
                background: var(--theme-color);
            }
        }
    }
}

.chip {
    white-space: wrap !important;
    background-color: var(--theme-color) !important;
    border-radius: 0 !important;
    padding-right: 20px !important;
    position: relative;
    min-height: 40px;

    &::before {
        z-index: 1;
        transform: rotate(-90deg);
        content: "";
        position: absolute;
        right: -18px;
        top: 15px;
        width: 0;
        height: 0;
        border-bottom: 14px solid var(--input-bg);
        border-left: 49px solid transparent;
    }
}

.tickets-2 {
    position: relative;
    padding-bottom: 17px;

    &::before {
        content: "";
        position: absolute;
        right: -40px;
        top: 36px;
        width: 0;
        height: 0;
        border-bottom: 25px solid var(--white-color);
        border-left: 100px solid transparent;
        z-index: 1;
        transform: rotate(-90deg);
    }

    &.tag-col-change-2 {
        position: relative;

        &::before {
            border-bottom: 25px solid var(--theme-color);
            right: -38px;
        }
    }
}

.tickets-text::before {
    clip-path: polygon(100% 0, 50% 0%, 100% 91%)
}

.tickets-text li:hover {
    background: transparent !important;
    color: var(--secondary);
    cursor: pointer;

}



.form-select {
    background-color: var(--input-bg);
    // margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary) !important;
    clip-path: var(--clip-input) !important;
    border: 0;
    padding: 10px 56px 10px 10px;
    border-radius: 0;
    position: relative;

    @media screen and (max-width: 400px) {
        padding: 10px 36px 10px 10px;
    }

    &:focus {
        box-shadow: none;
        outline: 0;
        border-color: transparent;
        background-color: var(--input-bg);
    }

    option {
        background-color: var(--input-bg) !important;
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary) !important;
        padding-top: 10px !important;
    }
}

// .form-select-white {
//     background-color: var(--white-color) !important;
// }

.searchWrapper {
    background-color: var(--input-bg) !important;
    margin-bottom: 0px !important;
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary) !important;
    clip-path: var(--clip-input) !important;
    border: 0 !important;
    padding: 10px 40px 10px 10px !important;
    border-radius: 0 !important;
    position: relative;

    &:focus {
        box-shadow: none;
        outline: 0;
        border-color: transparent;
        background-color: var(--input-bg) !important;
    }

    option {
        background-color: var(--input-bg) !important;
        font-size: 16px;
        font-weight: 500;
        color: var(--secondary) !important;
        padding-top: 10px !important;
    }
}

.searchWrapper {
    margin-bottom: 0;

    &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        right: 23px;
        top: 13px;
        background-image: url("../../../public/assets/icon/download.svg");
        background-position: center;
        background-size: 12px;
        background-repeat: no-repeat;
    }
}

.form-switch .form-check-input {
    width: 2.5rem;
    padding: 10px;
    box-shadow: none;
    border-color: var(--input-bg);
    cursor: pointer;
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-color: rgb(185, 185, 185);
    border: 0px;
}

.form-check-input:checked {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color);

    &.in-box {
        &:checked {
            background-color: var(--danger);
            border-color: var(--danger);
        }
    }
}

.form-check-input {
    padding: 12px;
    box-shadow: none;

    &:focus {
        box-shadow: none;
        outline: 0;
        border-color: var(--theme-color) !important;
    }
}

.out-line-check {
    &:checked {
        border-color: transparent;
    }
}

//  clears the ‘X’ from Chrome */
// input[type="search"]::-webkit-search-decoration,
// input[type="search"]::-webkit-search-cancel-button,
// input[type="search"]::-webkit-search-results-button,
// input[type="search"]::-webkit-search-results-decoration {
//     display: none;
// }

input[type="date" i]::before {
    @media screen and (max-width: 550px) {
        content: attr(placeholder);
        position: absolute;
    }
}

input[type="time" i]::before {
    @media screen and (max-width: 550px) {
        content: attr(placeholder);
        position: absolute;
    }
}

@media screen and (max-width: 768px) {

    input[type="date"]:focus::before,
    input[type="date"]:valid::before {
        content: "";
    }

    input[type="time"]:focus::before,
    input[type="time"]:valid::before {
        content: "";
    }
}

// input[type="date"]:before {
//     content: attr(placeholder) !important;
// }

// input[type="date"]:focus:before,
// input[type="date"]:valid:before {
//     content: "";
// }

.user-img-w {
    width: 50px;
    text-align: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
}