.order-det {
    .main-order {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid var(--theme-color);

        @media screen and (max-width : 678px) {
            flex-direction: column;
        }

        .ord-det {
            text-align: left;

            h4 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0;
                padding-bottom: 10px;
                color: var(--secondary);

                span {
                    font-size: 14px;
                    background-color: #dcdce1;
                    padding: 5px 20px;
                    position: relative;
                    border-radius: 15px;
                    font-weight: 500;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 9px;
                        top: 9px;
                        background-color: #5f6973;
                        width: 8px;
                        height: 8px;
                        border-radius: 50px;
                    }
                }

                .span-2 {
                    position: relative;
                    background-color: #f0d796;
                    margin-left: 10px;

                    &::before {

                        background-color: #ab7e2e;

                    }

                }
            }

            p {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0;
                padding-bottom: 10px;
                color: var(--secondary);
            }
        }

        .re-fund-edit {
            a {
                padding-right: 10px;
                text-decoration: underline;
                font-size: 16px;
                color: var(--theme-color);
            }
        }
    }
}

.unfulfilled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;

    h4 {
        font-size: 20px;
        font-weight: 600;
        color: var(--secondary);
    }

    p {
        img {
            width: 30px;
            cursor: pointer;
        }
    }

}

.listing-product {
    padding-top: 20px;

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray-color);
        gap: 20px;
        margin-bottom: 0;
        padding-bottom: 20px;

        li {

            p {
                font-size: 16px;
                font-weight: 500;
                color: var(--secondary);

                @media screen and (max-width:568px) {
                    border-right: 1px solid var(--secondary);
                    padding-right: 10px;
                }
            }

            &:last-child p {
                @media screen and (max-width:568px) {
                    border-right: 0;
                }
            }
        }
    }
}

.paid {
    padding-top: 30px;

    &_total {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.second-listing-product {
    padding-top: 10px;

    p {
        text-align: left;
        font-size: 20px;
        font-weight: 500;
        color: var(--theme-color);
    }

    ul {
        margin-bottom: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border-bottom: 1px solid var(--gray-color);
        padding-top: 10px;

        @media screen and (max-width : 768px) {
            padding: 0;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 10px;

            @media screen and (max-width : 568px) {
                span:first-child {
                    width: 100px;
                    display: block;
                    text-align: left;

                }

                span {
                    border-right: 1px solid var(--secondary);
                    padding-right: 10px;
                }

                span:last-child {
                    border-right: 0;
                    padding-right: 0;
                }
            }



            span {
                display: block;
            }
        }
    }
}

.user-det-fill-all {

    .not-card {
        margin-top: 15px;
        background-color: var(--input-bg);
        padding: 15px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 10px;

        h4 {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 0;
            color: var(--secondary);
            display: flex;

            a {
                font-weight: 500;
                color: var(--theme-color);
                text-decoration: underline;
                margin-left: auto;
            }
        }

        p {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0;
            color: var(--gray-color);
            padding-top: 20px;
            text-align: left;
        }
    }

    .customer {
        margin-top: 15px;
        background-color: var(--input-bg);
        border-radius: 10px;
        width: 100%;

        .joseph {
            border-bottom: 1px solid var(--gray-color);
            padding: 20px 15px;

            &:last-child {
                border-bottom: 0;
            }

            h4 {
                font-size: 16px;
                font-weight: 600;
                text-align: left;
                margin-bottom: 0;
                color: var(--secondary);
                display: flex;

                a {
                    font-weight: 500;
                    color: var(--gray-color);

                    margin-left: auto;
                }
            }

            &_name {
                text-align: left;
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: var(--theme-color);
                padding-top: 20px;
            }

            &_ed {
                color: var(--theme-color) !important;
            }

            &_order {
                font-size: 14px;
                text-align: left;
                padding-top: 5px;
            }

            &_m {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;

                a {
                    padding-top: 0;
                }

                img {
                    width: 20px;
                    cursor: pointer;
                }
            }

            &_prov {
                color: var(--gray-color);
                text-align: left;
                padding-top: 15px;
            }

            &_edit-image {
                width: 20px;
            }

            &_address-user {
                font-size: 16px;
                padding-top: 10px;
                text-align: left;
                margin-bottom: 0;

                address {
                    margin-bottom: 0;
                }
            }


        }


    }

    .summary {
        text-align: left;
        margin-top: 15px;
        background-color: var(--input-bg);
        padding: 15px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 10px;

        h4 {
            font-size: 16px;
            font-weight: 600;
            color: var(--secondary);
            text-align: left;
            margin-bottom: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-top: 10px;

            li {
                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--secondary);
                }
            }
        }
    }
}

.paid {
    table {
        tbody {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 500;
                    padding-bottom: 15px;
                }
            }
        }

        tfoot {
            border-top: 1px solid var(--gray-color);

            tr {
                td {
                    font-size: 16px;
                    font-weight: 500;
                    padding-top: 15px;
                }
            }
        }
    }
}

.full-items {
    display: flex;
    margin-top: 24px;
}

.time-line {
    margin-top: 20px;
    text-align: left;

    &_c {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--gray-color);

        h4 {
            font-size: 20px;
            font-weight: 600;
            color: var(--secondary);
            margin-bottom: 0;
        }
    }

    &_comments {
        padding-left: 50px;
        margin-top: 30px;
    }

    &_user-com {
        position: relative;
        padding-bottom: 40px;

        &::before {
            content: 'AC';
            position: absolute;
            left: -50px;
            top: 0;
            width: 40px;
            height: 40px;
            background-color: var(--input-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            left: -30px;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: var(--input-bg);

        }

        .email-2 {
            .tickets-2 {
                textarea {
                    margin-bottom: 5px;
                }
            }

            p {
                text-align: right;
                color: var(--gray-color);
                font-size: 16px;
                font-weight: 500;
            }
        }

    }

    &_user-com-add {
        position: relative;
        padding-bottom: 40px;

        &::before {
            content: '';
            position: absolute;
            left: -37px;
            top: 0;
            width: 15px;
            height: 15px;
            background-color: var(--gray-color);

            border-radius: 50px;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            left: -30px;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: var(--input-bg);

        }

        &:last-child {
            &::after {

                width: 0px;

            }
        }

        .email {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            @media screen and (max-width : 568px) {
                flex-direction: column;
            }

            p {
                font-size: 15px;
                font-weight: 500;
                color: var(--secondary);
            }

            p:last-child {
                color: var(--gray-color);
                width: 200px;
                text-align: right;
            }
        }

        .mailto {
            display: inline-block;
        }


    }
}

.PhoneInputInput {
    background-color: var(--input-bg) !important;
    border: 0;

    &:focus-visible {
        border: 0;
        outline: 0;
    }
}

.edit-icon-w {
    width: 20px;
    cursor: pointer;
}

.icon-w {
    width: 15px;
    cursor: pointer;
}