.input-mb {
    margin-bottom: 0;
}

.nn-b {
    .form-control {
        margin-bottom: 0;
    }
}

.sel-lane {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width : 769px) {
        flex-wrap: wrap;
    }

    .custom-action-button {
        @media screen and (max-width : 768px) {
            width: 100%;
        }
    }

    a {
        @media screen and (max-width : 768px) {
            width: 100%;
        }
    }
}

.ac-mem {
    overflow-x: hidden;


    @media screen and (min-width : 780px) {
        padding: 15px 5px 50px 5px !important;
    }

    @media screen and (max-width : 769px) {
        padding: 15px 5px 50px 5px !important;
    }
}

.firearm-option-page {
    margin-bottom: 0 !important;
    align-items: center !important;
    margin-top: 20px;
    padding-left: 0px !important;

    h5 {
        font-size: 20px;
        font-weight: 700;
        color: var(--theme-color);
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

.firearm-text {
    font-size: 16px;
    color: var(--secondary);
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px;
    text-align: left;
}

.select-text {
    margin-top: 30px;
    background-color: var(--theme-color);
    padding: 10px 5px;

    h2 {
        font-size: 24px;
        font-weight: 500;
        color: var(--white-color);
        margin-bottom: 0;
        text-align: left;
    }
}

.sec-gun-user-first {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 15px;

    .form-label {
        max-width: 100px;
        width: 100%;
        padding-bottom: 0;
    }

    .tickets {
        width: 100%;

        .form-select {
            margin-bottom: 0;

        }
    }
}

.reservation-details {
    border: 1px solid var(--theme-color);
    padding-bottom: 10px;

    &.pay-mode {


        .s-p-t {
            font-size: 16px;
            font-weight: 500;
            color: var(--secondary);
            margin-bottom: 0;
            padding-top: 15px;
            padding: 5px 10px;
        }
    }

    .r-d {
        background-color: var(--theme-color);
        padding: 6px 10px;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0;
        color: var(--white-color);
    }

    .date-select-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
            color: var(--secondary);
            display: flex;
            align-items: center;
            gap: 10px;
        }

        img {
            width: 15px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--secondary);
        }
    }

    .par {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
            width: 30px;
        }

        font-size: 14px;
        color: var(--secondary);
        font-weight: 600;
        margin-bottom: 0;
        text-align: left;
        padding-left: 10px;
    }

    .boj {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0px 10px 10px;

        &.boj-2 {
            align-items: flex-start;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--secondary);
            margin-bottom: 0;
            padding-left: 30px;
        }

        img {
            width: 40px;
        }
    }

    .listing-firearm {
        padding: 10px;

        ul {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--secondary);
            list-style: none;
            padding-left: 0;
            justify-content: space-between;
            gap: 15px;
            background-color: rgba(128, 128, 128, 0.274);
            padding: 7px 3px;
        }
    }
}

.exp-add {
    .exp-add-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--theme-color);
        padding-bottom: 10px;

        @media screen and (max-width : 769px) {
            flex-direction: column;
            row-gap: 20px;
        }

        &_1 {
            display: flex;
            align-items: center;
            gap: 10px;

            @media screen and (max-width : 769px) {
                width: 100%;
            }

            .select-input {
                @media screen and (min-width : 769px) {
                    width: 250px;
                }

            }

            .fa {
                width: 30px;
            }

            .bar-code {
                width: 30px;
            }
        }


    }

    .us-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--theme-color);
        padding-bottom: 10px;
        flex-wrap: wrap;
        row-gap: 15px;
        gap: 10px;

        @media screen and (max-width : 769px) {
            margin-bottom: 10px;
        }

        .n_m {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .m-b-w {
            display: flex;
            align-items: center;
            gap: 5px;

            img {
                width: 20px;
            }

            font-size: 14px;
            color: var(--secondary);
            font-weight: 500;
            margin-bottom: 0;
        }

        .gift {
            background-color: #ea7b446b;
            padding: 5px;
            border-radius: 5px;
        }
    }

    .exp-add-listing {

        ul {
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            min-height: 300px;
            max-height: 350px;
            overflow-y: auto;
            padding-right: 10px;

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: var(--input-bg);
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: var(--theme-color);
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid var(--input-bg);
                padding: 10px;
                column-gap: 10px;
                color: var(--secondary);
                cursor: pointer;

                &:hover {
                    background-color: var(--theme-color);
                    color: var(--white-color);
                }

                &.exp-active {
                    background-color: var(--theme-color);
                    color: var(--white-color);
                }

                p {
                    font-size: 16px;

                    font-weight: 500;
                    margin-bottom: 0;

                    .box-bg {
                        flex: 1 0 45px;
                    }
                }

                &:last-child {
                    border-bottom: 0px solid transparent;
                }
            }
        }

        &_name-image {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .user-btn-select {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

.outer-over {
    @media screen and (max-width : 769px) {
        margin-top: 70px !important;
        height: calc(100vh - 70px) !important;
    }

    @media screen and (max-width : 350px) {
        margin-top: 90px !important;
        height: calc(100vh - 90px) !important;
    }
}

.outer-over-2 {
    @media screen and (max-width : 769px) {
        margin-top: 70px !important;
        height: calc(100vh - 70px) !important;
    }

    @media screen and (max-width : 569px) {
        margin-top: 40px !important;
        height: calc(100vh - 40px) !important;
    }
}

// radio button custom css start

.suman {
    margin-top: 20px;
    padding: 0 10px;

    .select_time_wrapper label {
        background-color: var(--white-color);
        width: 100%;
        margin-right: 7px;
        color: var(--secondary) !important;
        margin-bottom: 10px;


    }

    .select_time_wrapper label span {
        font-size: 14px;
        font-weight: 600;
        border: 2px solid #ccc;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            background-color: var(--theme-color);
            color: var(--white-color);
            border: 2px solid var(--white-color);
        }
    }

    .select_time_wrapper label input {
        position: absolute;
        top: -20px;
    }

    .select_time_wrapper input:checked+span {
        background-color: var(--theme-color);
        color: var(--white-color);
        border: 2px solid var(--theme-color);
    }

    img {
        filter: brightness(3) invert(2);
    }

    .ter-img {
        height: 22px;
    }

    .select_time_wrapper input:checked+span img {
        filter: brightness(0) invert(2);
    }

    .select_time_wrapper input:hover+span img {
        filter: brightness(0) invert(2);
    }



    .card-pay {
        width: 30px;
        margin-right: 10px;
    }

}

.suman-lint {
    .card-pay {
        width: 60px;
    }

    .select_time_wrapper input:checked+span img {
        filter: brightness(0) invert(1);
    }
}


// radio button custom css end

.discount-card {
    .discount-payment {
        background-color: var(--input-bg);
        border-radius: 15px;
        padding: 10px;
        margin-bottom: 20px;

        .d-c {
            display: flex;
            gap: 10px;

            align-items: center;
            margin-bottom: 0;

            .d-c-g {
                display: block;

                img {
                    width: 30px;
                }
            }
        }

        .offered-text {
            font-size: 14px;
            font-weight: 500;
            color: var(--secondary);
            margin-bottom: 0;
            padding: 10px 0;
            text-align: left;
        }

        .cou-pon {
            .tickets {
                .input-theme {
                    background-color: var(--white-color);
                }

                &::before {
                    border-bottom: 14px solid var(--input-bg);
                    border-left: 49px solid transparent;
                }
            }
        }
    }

    .sub-total {
        background-color: var(--input-bg);
        border-radius: 15px;
        padding: 10px;
        margin-bottom: 20px;

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;

                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--secondary);
                    margin-bottom: 0;
                }
            }

            .first-total {
                p {
                    font-size: 18px;
                    font-weight: 600;
                    padding-bottom: 10px;
                }
            }

            .last-footer {
                border-top: 1px solid var(--secondary);
                margin-top: 15px;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    padding-top: 10px;
                }
            }
        }
    }
}

.order-top-th {
    max-height: 300px;
    overflow: auto;

    @media screen and (max-width : 769px) {
        max-height: 100%;
    }

    .table-responsive {

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: var(--theme-color);

        }
    }

    th {
        position: sticky;
        top: 0;
        background-color: var(--white-color);
    }

    td {
        p {
            min-width: 80px;
            max-width: 200px;
        }
    }
}

.mem-tow {
    @media screen and (max-width : 769px) {
        flex-direction: column !important;
    }

    .back-listing-tab {
        @media screen and (max-width : 769px) {
            width: 100%;
        }

        a {
            transform: translate(-20px, 17px);
        }
    }

    .new-back-btn-add {
        display: flex;
        justify-content: space-between !important;

        @media screen and (max-width : 768px) {
            align-items: flex-end !important;
            transform: translateY(4px) !important;
        }

        .button-width {
            @media screen and (max-width : 769px) {
                display: block !important;
                width: 55px;
                transform: translate(-29px, 13px);
            }

            @media screen and (max-width : 569px) {

                transform: translate(-20px, 13px);
            }
        }

        .as {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 10px;
        }
    }
}

.menu-button-listing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    grid-gap: 10px;
    width: 100%;

    @media screen and (max-width : 510px) {
        grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
    }
}

.participants-button {
    width: 100%;
    text-align: right;

    a {
        display: inline-block;
    }
}

.user-id-profile-list {
    height: 100%;
    padding: 0;
    display: block;


    .sign-in-card {
        max-width: 100%;
        text-align: left;
        position: relative;

        h4 {
            font-size: 22px;
            font-weight: 600;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0;
            padding-bottom: 5px;
        }

        .user-confirm {
            position: absolute;
            right: 20px;
            top: 10px;
            display: flex;
            gap: 5px;

            img {
                width: 23px;
                cursor: pointer;
            }
        }
    }
}

.user-id-list {
    cursor: pointer;

    .box-bg {
        flex: 1 0 43px;
    }
}

.c-pass-word {
    text-decoration: underline;
    display: block;
    text-align: right;
    cursor: pointer;
    color: var(--secondary);

    &:hover {
        color: var(--theme-color);
    }
}

.or {
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 0;
    padding: 0px 0 30px;
}

.successfully {
    text-align: center;

    img {
        width: 120px;
    }

    h4 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 20px;
        color: var(--theme-color);
    }
}

.cash-price {
    font-size: 23px;
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 0;
    padding: 0 0 30px;
}

.optionListContainer {
    z-index: 9 !important;
}

.u-d-p {
    p {
        font-size: 16px;
        font-weight: 500;
        padding: 20px 0;
        margin-bottom: 0;
        color: var(--theme-color);
        text-decoration: underline;
        cursor: pointer;
    }
}

.show-form {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.dis-btn {
    background-color: #eaeaea !important;
    opacity: 0.6;
    cursor: not-allowed !important;

    .white-bg {
        background-color: #f2f2f2 !important;
        color: #a5a5a5 !important;
        opacity: 0.7;
        pointer-events: none;

        img {
            filter: brightness(3) invert(8);
            opacity: 0.2;
        }
    }

}

.dis-btn-tab {
    background-color: #eaeaea !important;
    opacity: 0.6 !important;
    cursor: not-allowed !important;

    button {
        background-color: #f2f2f2 !important;
        color: #a5a5a5 !important;
        opacity: 0.7 !important;
        cursor: not-allowed;
        border-bottom: 0px solid !important;

        img {
            filter: brightness(3) invert(8) !important;
            opacity: 0.2;
        }

        span {
            color: #a5a5a5 !important;
        }
    }
}

.start-b {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    padding-top: 10px;
    color: var(--theme-color);
    text-decoration: underline;
}

.fw-bold p {
    color: var(--theme-color);
}

.view-icon-order {
    width: 25px;
}

.p-d-icon {
    width: 17px;
    cursor: pointer;
}

.printer-icon {
    width: 25px;
    cursor: pointer;
}

.active-not-active-range {
    .range-heading {
        text-align: left;
        font-size: 17px;
        font-weight: 500;
        color: #000;
        border-bottom: 2px solid var(--theme-color);
        padding: 5px;
    }

    ul {
        list-style: none;
        display: flex;
        padding: 0;
        // width: 35px;
        min-height: 300px;
        max-height: 500px;
        gap: 10px;
        margin-bottom: 0;
        cursor: pointer;

        li {
            text-align: center;
            flex: 0 0 35px;
            writing-mode: vertical-lr;
            text-orientation: upright;
            display: flex;
            // justify-content: flex-end;
            justify-content: space-between;
            position: relative;
            color: var(--white-color);
            font-size: 17px;
            font-weight: 500;
            border: 1px solid var(--theme-color);
            word-spacing: -10px;
            align-items: center;
            padding: 3px 0;
            white-space: nowrap;
            gap: 10px;

            img {
                max-height: 25px;
                filter: brightness(0) invert(1);
                // position: absolute;
                // top: 5px;
                // left: 4px;
            }

            &.bg-black-lane img {
                filter: brightness(1) invert(0);
            }

            &:hover {
                background-color: var(--theme-color);
                color: var(--white-color);

            }

            &:hover img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .bg-org {
        background-color: var(--theme-color);
        color: var(--white-color);
    }

    .bg-gray {
        background-color: var(--input-bg);
        color: var(--secondary);
    }

    .bg-black-lane {
        background-color: transparent;
        color: var(--secondary);
    }

    .bg-red {
        background-color: var(--danger);
        color: var(--white-color);
        cursor: not-allowed;


        &:hover {
            background-color: var(--danger) !important;
        }
    }


}

.a-and-n {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    padding-left: 10px;

    p {
        @media screen and (max-width : 569px) {
            font-size: 13px;
        }
    }

    .empty {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: -14px;
            top: 5px;
            width: 12px;
            height: 12px;
            border: 1px solid var(--theme-color);
        }
    }

    .reserved {
        @extend .empty;

        &::after {
            border: 1px solid var(--input-bg);
            background-color: var(--input-bg);
        }
    }

    .choice {
        @extend .empty;

        &::after {
            border: 1px solid var(--theme-color);
            background-color: var(--theme-color);
        }
    }

    .booked {
        @extend .empty;

        &::after {
            border: 1px solid var(--danger);
            background-color: var(--danger);
        }
    }
}

.experience-sel {
    display: flex;
    gap: 10px;

    @media screen and (max-width : 570px) {
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 570px) {
    .expe-2 {
        transform: translate(-20px, 53px) !important;
        width: 45px;
    }
}

.exp-h {
    @media screen and (max-width : 769px) {
        flex: 0 0 180px;
    }

    @media screen and (max-width : 669px) {
        flex: 0 0 100%;
    }
}

.t-m-b {
    margin-bottom: 15px;
}

.card-table {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @media screen and (max-width : 789px) {
        grid-template-columns: 1fr;
    }

    .c-t-c {
        border: 1px solid var(--theme-color);
        padding: 10px;

        .c-t {
            display: flex;
            gap: 10px;
            padding-bottom: 10px;

            .listing-event {
                flex: 0 0 130px;
            }
        }
    }
}

.red-bg-color {
    background-color: var(--danger);
    color: var(--white-color);
}

.c-t-w {
    white-space: pre-wrap;
    min-width: 100px;
    max-width: 400px;
    word-wrap: break-word;
}

.payment-card-tm {
    // height: 300px;
    display: flex;
    flex-direction: column;

    .pay-pay {
        margin-top: 40px;
    }
}

.t-modal-no thead th {
    border-bottom: 2px solid rgba(128, 128, 128, 0.674) !important;
    background-color: rgba(128, 128, 128, 0.274);
    font-size: 14px;
    color: var(--secondary);
}

.t-modal-no tbody td {
    font-size: 14px;
    color: var(--secondary);
}

.f-modal {
    width: 150px;
    text-align: left;
}

.l-modal {
    text-align: right;
}

.tf-y {
    @media screen and (max-width : 569px) {
        transform: translateY(-50px);
    }
}

.h-set-dd {
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary);
}

.card-listing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    text-align: start;
    gap: 10px;

    @media screen and (max-width : 992px) {
        grid-template-columns: 1fr;
    }

    .card-con {
        border: 1px solid var(--theme-color);
        padding: 6px;

        .car-d {
            display: grid;
            grid-template-columns: 0.6fr 1fr;
            gap: 5px;


            p {
                font-size: 15px;
                font-weight: 500;
                color: var(--secondary);
                padding: 5px 0;

                &:first-child {

                    display: flex;
                    gap: 5px;
                    justify-items: center;
                    justify-content: space-between;
                }
            }

        }


    }
}

.c-f-s {
    font-size: 14px;
    font-weight: 500;
    color: var(--danger);

}

.o-f-s {
    font-size: 14px;
    font-weight: 500;
    color: rgb(20, 148, 20);

}

.dis-aa {
    cursor: not-allowed;
}