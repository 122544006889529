.custom-all-button {
    padding: 7px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    outline: 0;
    border: 0;
    margin: 10px 0;
    box-shadow: none;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;

    &.parrot-button {
        background-color: var(--success);
        color: var(--secondary);

        &.parrot-size {
            padding: 2px 20px;
        }
    }

    &.theme-button {
        background-color: var(--theme-color);
        color: var(--white-color);

        &.parrot-size {
            padding: 2px 20px;
            background-color: var(--danger);
        }
    }

    &.card-custom-btn {
        background-color: transparent;
        border: 2px solid var(--theme-color);
        color: var(--secondary);
        width: 100%;
        padding: 8px 40px;

        &:hover {
            background-color: var(--theme-color);
            color: var(--white-color);
        }
    }

    &.gray-button {
        background-color: var(--gray-color);
        color: var(--white-color);
    }
}

.close-button-icon {
    box-shadow: none;
    outline: 0;
    background-color: transparent;
    border: 0;

    img {
        margin-top: 0;
    }
}

.active-tab {
    background-color: var(--theme-color) !important;
    color: var(--white-color) !important;
}

.success-bg-fill {
    background-color: var(--success) !important;
    pointer-events: none !important;
}

.danger-bg-fill {
    background-color: var(--danger) !important;
    pointer-events: none !important;
}

.verified {
    background-color: var(--success);
    border: 0;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
    color: var(--secondary);
    font-style: normal;
    font-weight: 600;
    pointer-events: none;
}

.expired {
    @extend .verified;
    background-color: var(--danger);
}