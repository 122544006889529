.calender-list {
    margin-top: 20px;
}

.calendar {
    thead {
        background-color: var(--theme-color);

        th {
            color: var(--white-color);
            padding: 0 0px;
            text-align: left;

        }
    }

    tbody {

        td:nth-child(odd),
        td:nth-child(even) {
            padding: 10px 10px 10px;
            color: var(--theme-color);
            background-color: var(--white-color);
            width: 200px;
            border-bottom: 1px solid rgba(151, 147, 147, 0.308);

            &:hover {
                background-color: var(--even-color);
                cursor: pointer;
            }

            &.active {
                // border: 2px solid var(--theme-color);
                color: var(--theme-color);
                background-color: transparent;
                font-weight: 800;
                // border-width: medium;
                border: 2px solid var(--theme-color);

            }

            &.prev-month {
                // background-color: #eaeaea;
                color: rgb(151, 147, 147);
            }

            &.next-month {
                background-color: var(--even-color);
            }

            &.border-remove {
                border-bottom: 0px solid;
            }
        }

        td:nth-child(even) {
            background-color: var(--even-color);
        }

    }


}