:root {
    --theme-color: #EA7B44;
    --secondary: #000000;
    --success: #9AFF00;
    --danger: #FF0000;
    --white-color: #fff;
    --gray-color: #919090;
    --gray-dark: #212121;
    --input-bg: #EAEAEA;
    --bg-gray: #191919;
    --green-color: #198754;
    --even-color: #eaeaea;
}



$theme-color: var(--theme-color);
$secondary-color: var(--secondary);
$success-color: var(--success);
$danger-color: var(--danger);
$white-color: var(--white-color);
$button-color: var(--gray-color);
$gray-dark: var(--gray-dark);
$input-bg-color: var(--input-bg);
$all-bg-color: var(--bg-gray);
$green: var(--green-color);
$bg-even: var(--even-color);

.items-can {
    background-color: var(--danger);
}

.items-suc {
    background-color: #EA7B44;
}