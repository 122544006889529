//loading button width set css start
.save-w {
    width: 128.33px !important;
    padding: 8px 0 !important;
}

.delete-w {
    width: 138px !important;
    padding: 8px 0 !important;
}

.create-range-w {
    width: 196.8px !important;
    padding: 8px 0 !important;

    @media screen and (max-width : 499px) {
        width: 150px !important;
    }
}

.create-range-2 {
    width: 220px !important;
    padding: 8px 0 !important;

}

// loading button width set css end

// custom radio button create css add start

.custom-radio-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.custom-radio {
    display: inline-flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
    justify-content: center;
}

.custom-radio input {
    display: none;
}

.custom-radio-label {
    font-size: 16px;
    color: #333;
}

.custom-radio.checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}

.custom-radio.checked .custom-radio-label {
    color: var(--white-color);

}

.custom-radio.checked::before {
    background-color: var(--theme-color);
    display: none;
}

// custom radio button create css add end

// Tab new create css new add start
.nav-tabs {
    border-bottom: 2px solid var(--theme-color);
    padding-left: 0;
    gap: 0;
    width: 100%;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 100%;
        height: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--theme-color);

    }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: var(--theme-color);
    isolation: isolate;
}

.nav-tabs .nav-link {
    font-weight: 500;
    white-space: nowrap;
}



// // Tab new create css new add end