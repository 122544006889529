.button-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 30px solid var(--input-bg);
        border-right: 30px solid transparent;
        transform: rotate(-90deg);
        z-index: 1;
    }

    &::after {
        right: 0;
        left: auto;
        transform: rotate(180deg);
    }
}

.button-wrapper .label-input {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid var(--input-bg);
    cursor: pointer;
    color: var(--secondary);
    padding: 50px 50px;
    font-size: 15px;
    font-weight: 500;

    strong {
        display: block;
        font-size: 20px;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 30px solid var(--input-bg);
        border-right: 30px solid transparent;
        z-index: 2;
    }

    &::after {
        right: 0;
        left: auto;
        transform: rotate(90deg);
    }
}

.input-image-sel {
    width: 100px;
    height: 100px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

// input image upload css



.file-input {
    position: relative;
    width: 100%;
    height: 200px;
    border: 2px solid var(--input-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 30px solid var(--input-bg);
        border-right: 30px solid transparent;
        z-index: 1;
    }

    &::after {
        right: 0;
        left: auto;
        transform: rotate(90deg);
    }
}

.banner-input {

    @extend .file-input;
    width: 100%;
    min-height: 200px;
    max-height: 384px;
}

.input__image {
    position: relative;
    background-color: #f8f8f8;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 30px solid var(--input-bg);
        border-right: 30px solid transparent;
        transform: rotate(-90deg);
        z-index: 1;
    }

    &::after {
        right: 0;
        left: auto;
        transform: rotate(180deg);
    }
}

.file-input input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    z-index: 1;
}

.preview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    position: relative;

}

.preview img {
    max-width: 100%;
    max-height: 100%;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay__v {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.image-container:hover .overlay__v {
    opacity: 1;
}

.delete-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;

    img {
        width: 35px;
        filter: brightness(0) invert(1);
    }
}

.upload-image-text-data {
    padding: 0 10px;

    h4 {
        font-size: 20px;
        color: var(--secondary);
        font-weight: 600;
        margin-bottom: 0;
    }

    p {
        font-size: 16px;
        color: var(--secondary);
        margin-bottom: 0;
        font-family: 500;
    }
}