// range-desk css start

.modal-table {
    background-color: transparent;

    .table-header {
        flex-direction: column;
        text-align: center;
        display: flex;
        border-bottom: 1px solid transparent;

        h5 {
            color: var(--white-color);
            font-size: 18px;
            font-weight: 700;
            padding: 30px 0;
        }

    }

    .identity-card {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

        @media screen and (max-width : 768px) {
            padding: 0 12px;
        }

        &__border {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: -77px;
                top: 70px;
                border: 2px dashed var(--theme-color);
                width: 100px;
                z-index: -1;

                @media screen and (max-width : 768px) {
                    right: -90px
                }
            }
        }


        &__body {
            max-width: 150px;
            width: 200px;

            h4 {
                @extend .sub-heading;
                color: var(--white-color);
                padding: 20px 0;
            }
        }
    }

    .identity-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        margin: 25px auto 0;
        border-top: 0px solid;
        gap: 12px;

        @media screen and (max-width : 768px) {
            flex-wrap: nowrap;
            padding: 20px 5px;
        }
    }
}

// range-desk css end

.modal-backdrop.show {
    opacity: 0.9;
}

.check-button {
    border-top: 0px solid;
}

.user-check {
    display: flex;
    flex: 1;

    &__head {
        display: flex;
        align-items: flex-start;
        position: relative;
        gap: 5px;
        flex: 1;
    }

    &__id-list {
        flex: 1;
    }

    &__img-name {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 60px;
            top: 30px;
            height: 100%;
            width: 3px;
            border: 2px dashed var(--theme-color);
        }
    }

    &__line-mob {
        // @media screen and (max-width : 370px) {
        //     &::before {

        //         left: 56px;

        //     }
        // }
    }

    &__title {
        h2 {
            @extend .theme-heading;
            color: var(--white-color);
            font-size: 1.3rem;
        }

        p {
            font-size: 15px;
            color: var(--success);
            margin-bottom: 0;
        }
    }

    &__name {
        display: flex;

        flex: 1;
        padding-left: 45px;
        position: relative;
        z-index: 0;

        // @media screen and (max-width : 365px) {
        //     padding-left: 46px;
        // }



        // &::after {
        //     content: '';
        //     position: absolute;
        //     left: 71px;
        //     top: 45px;
        //     width: calc(100% - 250px);
        //     height: 3px;
        //     border: 2px dotted var(--theme-color);
        //     z-index: -1;

        //     @media screen and (max-width : 768px) {
        //         width: 3px;
        //         height: calc(100% - 140px);
        //         left: 55px;
        //     }
        // }

        @media screen and (max-width : 768px) {
            flex-direction: column;
        }
    }

    &__out-line-right {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            right: 0px;
            top: 17px;
            width: calc(100% - 29px);
            height: 3px;
            border: 2px dashed var(--theme-color);
            z-index: -1;

            @media screen and (max-width : 768px) {
                width: 3px;
                height: 100%;
                left: 15px;
                top: 35px;
            }
        }
    }

    &__month {
        max-width: 200px;
        width: 100%;
        margin-top: 30px;

        h4 {
            color: var(--white-color);
            font-size: 16px;
            font-weight: 800;
        }

        p {
            color: var(--white-color);
            font-size: 13px;
            font-style: italic;
            margin-bottom: 50px;

            @media screen and (max-width : 768px) {
                margin-bottom: 20px;
            }
        }

        @media screen and (max-width : 768px) {
            display: flex;
            gap: 20px;
            // margin-top: 10px;
            max-width: 100%;
        }


    }

    &__cst {
        padding: 0px 20px 20px 0;

        @media screen and (max-width : 768px) {
            padding: 0;
            width: 130px;
            text-align: left;
        }
    }
}

.delete-fill-detail {
    padding: 4%;
    text-align: center;

    .id-file {
        h2 {
            font-size: 40px;
            font-weight: 800;
            color: var(--secondary);
        }

        p {
            font-size: 15px;
            color: var(--secondary);
        }
    }
}