.sign-in {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 20px 20px 0;

    h3 {

        font-size: 22px;
        font-weight: 600;
        color: var(--secondary);
        text-align: center;
        padding: 20px 0 0;
    }

    .sign-logo {
        width: 100px;
        height: 100px;
    }

    .sign-in-card {
        max-width: 400px;
        width: 100%;
        border: 2px solid var(--theme-color);
        padding: 20px 30px;
        background-color: #fff;


        @media screen and (max-width : 512px) {
            padding: 20px;
            max-width: 100%;
        }

        .sign-form {


            .form-label {
                font-size: 16px;
                font-weight: 500;
                color: var(--secondary);
                display: block;
                text-align: left;

            }

            .form-label-2 {
                font-size: 16px;
                font-weight: 500;
                color: var(--secondary);

                text-align: left;

            }

            .form-control {
                margin-bottom: 10px !important;
            }
        }

        .password {
            position: relative;

            .input-group-text {
                background-color: transparent !important;
                border: 0;
                cursor: pointer;
                height: 50px;
                padding: 10px 7px;
                position: absolute;
                right: 0;
                top: 0;

                img {
                    width: 20px;
                }
            }
        }


    }

}

.pass-far {
    display: block;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    padding-top: 10px;
    text-decoration: underline;
    color: #0d6efd;
    padding: 10px 0;

    &:hover {
        color: var(--theme-color);
    }
}

.error {
    font-size: 16px;
    font-weight: 500;
    color: var(--danger);
    margin: 0;
    padding-bottom: 0px;
    margin-bottom: 0;
}

.successfully {
    @extend .error;
    color: var(--green-color);
}