@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-XLightItalic.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-XLight.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-BookItalic.otf');
    font-weight: 400;
}


@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-Book.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-BookItalic.otf');
    font-weight: 800;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../../public/assets/fonts/Gotham-Bold.otf');
    font-weight: 800;
}